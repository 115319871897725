import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading, login} from "../../../redux/meta/MetaActions";
import {SignUpBody, UserApi} from "client";
import getConfig from "../../../utils/getConfig";
import InputCheckbox from "../../inputs/InputCheckbox";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	onClose(getNewData: boolean): void;
}

const defaultAddUserForm: SignUpBody = {
	email: "",
	password: "",
	confirmPassword: "",
	agreeToTermsAndConditions: false
};

/**
 * Modal for displaying sign up information.
 */
const SignUpModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen} = props;
	const [form, setForm] = useState<SignUpBody>(defaultAddUserForm);

	function closeHelper(): void {
		setForm(defaultAddUserForm);
		props.onClose(false);
	}

	function createOnChange(key: keyof SignUpBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	function changeFormCheckbox(value?: boolean) {
		setForm({
			...form,
			agreeToTermsAndConditions: value,
		});
	}

	async function submitAddNewUser(e): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new UserApi(getConfig()).signUp({signUpBody: form});
			setForm(defaultAddUserForm);
			props.onClose(true);
			props.dispatch(login(res.token));
			props.dispatch(decrementLoading());
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			toggle={closeHelper}
			size="lg"
		>
			<ModalHeader toggle={closeHelper} className="border-0"><span className="h3">Create New Account</span></ModalHeader>

			<form onSubmit={submitAddNewUser}>
				<ModalBody>
					<div className="mb-3">
						<Label>
							Enter Email Address
						</Label>
						<input
							value={form.email}
							placeholder="Enter email address..."
							onChange={createOnChange("email")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Password*
						</Label>
						<input
							type="password"
							value={form.password}
							placeholder="Set a password..."
							onChange={createOnChange("password")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Confirm Password*
						</Label>
						<input
							type="password"
							value={form.confirmPassword}
							placeholder="Confirm password..."
							onChange={createOnChange("confirmPassword")}
						/>
					</div>

					<div>
						<span>A verification email will be sent to your email address, please check your inbox.</span>
						<div className="pt-3">
							<InputCheckbox
								value={form.agreeToTermsAndConditions}
								onToggle={changeFormCheckbox}
							>
								I agree to the <a href={"/"} target="_blank" rel="noopener noreferrer">Terms and Services.</a>
							</InputCheckbox>
						</div>
					</div>
				</ModalBody>

				<ModalFooter className="border-0">
					<Button
						type="submit"
						color="blue"
						onSubmit={submitAddNewUser}
					>
						Create an Account
					</Button>
				</ModalFooter>

			</form>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(SignUpModal);
