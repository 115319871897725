import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Col, Container, Row} from "reactstrap";
import {$blue, $cyan, $gray, $white} from "../style/_colors";
import {Link} from "react-router-dom";
import InputCheckbox from "../components/inputs/InputCheckbox";
import SelectDropdown from "../components/inputs/SelectDropdown";
import CurrencyInput from "../components/inputs/CurrencyInput";
import AccordionElement from "../components/accordian/AccordianElement";
import {FaSearch} from "react-icons/all";
import CryptoContainer from "../components/CryptoContainer";
import {IStore} from "../redux/defaultStore";

import CryptoTableContainer from "../components/transactionHistoryTable/CryptoTableContainer";
import SearchInput from "../components/inputs/SearchInput";
import CryptoAutoComplete, {IAutoCompleteOption} from "../components/inputs/CryptoAutoComplete";

interface IProps {
	dispatch?: any;
	token?: any;
}

const testOptions: IAutoCompleteOption[] = [
	{label: "test", value: "1"},
	{label: "best", value: "2"},
	{label: "nest", value: "3"},
	{label: "rest", value: "4"},
]

const testAccordionContent: any[] = [
	{
		title: "Q: Test question on the accordion 1",
		content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
	{
		title: "Q: Test question on the accordion 2",
		content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
	{
		title: "Q: Test question on the accordion 3",
		content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
];

const DemoPage: React.FC<IProps> = (props: IProps) => {

	const [test1, setTest1] = useState(false);
	const [test2, setTest2] = useState(true);
	const [test3, setTest3] = useState([]);
	const [test4, setTest4] = useState([]);
	const [test5, setTest5] = useState();
	const [test6, setTest6] = useState();
	const [autoCompleteSelections, setAutoCompleteSelections] = useState<string[]>([]);
	const [signUpModal, setSignUpModal] = useState(false);

	useEffect(() => {
	}, []);
	function toggleSignUpModal() {
		setSignUpModal(!signUpModal);
	}

	return (
		<CryptoContainer
			signUpModal={signUpModal}
			toggleSignUpModal={toggleSignUpModal}
		>
			<Container className="demo-page-container">
				<Row>
					<Col xs={12}>
						<h1>Billcy UI Style Guide</h1>
					</Col>

					<Col xs={12} className="py-3">
						<h2>Colors & Typography</h2>
					</Col>

					<Col xs={12} md={6} className="demo-page-container__color-palette">
						<Row className="flex-wrap">

							<Col xs={6} md={3} className="d-flex flex-column align-items-center">
								<div style={{height: 100, width: 100, borderRadius: "50%", backgroundColor: $blue}}/>
								<p>{$blue}</p>
							</Col>
							<Col xs={6} md={3} className="d-flex flex-column align-items-center">
								<div style={{height: 100, width: 100, borderRadius: "50%", backgroundColor: $cyan}}/>
								<p>{$cyan}</p>
							</Col>
							<Col xs={6} md={3} className="d-flex flex-column align-items-center">
								<div className="shadow"
								     style={{height: 100, width: 100, borderRadius: "50%", backgroundColor: $white}}/>
								<p>{$white}</p>
							</Col>
							<Col xs={6} md={3} className="d-flex flex-column align-items-center">
								<div style={{height: 100, width: 100, borderRadius: "50%", backgroundColor: $gray}}/>
								<p>{$gray}</p>
							</Col>
						</Row>
					</Col>

					<div className="clearfix"/>

					<Col xs={12}>
						<h1>This is an h1 tag</h1>
						<h2>This is an h2 tag</h2>
						<h3>This is an h3 tag</h3>
						<h4>This is an h4 tag</h4>
						<p>This is a p tag</p>
						<Link to="/test">This is an a tag</Link>
					</Col>

					<Col xs={12} className="d-flex flex-row pt-4">
						<Row>

							<Col xs={12} md={6} className="w-100 d-flex flex-column">
								<Button color="blue">Primary Button</Button>
								<Button color="outline-blue">Border Variant</Button>
								<Button color="cyan">Primary Button</Button>
								<Button color="outline-cyan">Border Variant</Button>
								<Button color="white">Dark Background</Button>
								<div className="d-flex flex-column mt-1 pt-1 pl-1 pr-1 rounded"
								     style={{width: 250, backgroundColor: "black", marginLeft: -5}}>
									<Button color="outline-white">Border Variant</Button>
									<p className="text-white text-center pt-1">in a black box</p>
								</div>
							</Col>
							<Col xs={12} md={6} className="w-100 d-flex flex-column py-3">
								<div>
									<label className="custom-radio">This is a an active radio toggle
										<input type="radio" readOnly={true} checked={true} name="radio"/>
										<span className="checkmark"/>
									</label>
									<label className="custom-radio">This is an inactive radio toggle
										<input type="radio" readOnly={true} checked={false} name="radio"/>
										<span className="checkmark"/>
									</label>
								</div>
								<div className="d-flex fled-row py-3">
									<div className="pr-3">
										<InputCheckbox value={test1} onToggle={setTest1} disabled={true}>This is an
											active checkbox</InputCheckbox>
									</div>
									<InputCheckbox value={test2} onToggle={setTest2} disabled={true}>This is an inactive
										checkbox</InputCheckbox>
								</div>
							</Col>

						</Row>
					</Col>

					<Col xs={12} className="py-4">
						<label>Text Input</label>
						<input/>
					</Col>

					<Col xs={12} className="py-4">
						<label>Text Area</label>
						<textarea />
					</Col>

					<Col xs={12} className="pb-4">
						<label>Select Dropdown</label>
						<SelectDropdown options={testOptions} placeholder="Placeholder Text..." name="test"
						                value={test3} setValue={setTest3} multiSelect={true}/>
					</Col>

					<Col xs={12} className="pb-4">
						<label>Searchable Dropdown</label>

						<CryptoAutoComplete
							placeholder="Enter your test here..."
							options={[
								{label: "This is option 1", value: "1"},
								{label: "This is option 2", value: "2"},
								{label: "This is option 3", value: "3"},
								{label: "This is option 4", value: "4"},
								{label: "This is option 5", value: "5"},
								{label: "This is option 6", value: "6"},
								{label: "This is option 7", value: "7"},
								{label: "This is option 8", value: "8"},
								{label: "This is option 9", value: "9"},
								{label: "This is option 10", value: "10"},
							]}
							selections={autoCompleteSelections}
							setSelections={setAutoCompleteSelections}
							multiSelect={false}
							inputValueOnChange={setTest6}
						/>
					</Col>

					<Col xs={12} className="pb-4">
						<label>Input Search Input</label>
						<SearchInput/>
					</Col>

					<Col xs={12} className="pb-4">
						<label>Search Input</label>
						<SelectDropdown options={testOptions} placeholder="Placeholder Text..." name="test"
						                value={test4} setValue={setTest4} searchable={true} icon={<FaSearch/>}/>
					</Col>

					<Col xs={6} sm={6} className="pb-4">
						<label>Monetary Value Input</label>
						<CurrencyInput name="test-number" value={test5} setValue={setTest5}/>
					</Col>

					<Col xs={12} className="py-3">
						{testAccordionContent.map(AccordionElement)}
					</Col>

					{/*<Col xs={12}>*/}
					{/*	<h2>Frame One Table</h2>*/}
					{/*	<CryptoTableContainer/>*/}

					{/*</Col>*/}

				</Row>
			</Container>

		</CryptoContainer>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(DemoPage);
