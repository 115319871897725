import React, {useState} from "react";
import {connect} from "react-redux";

import CryptoContainer from "../components/CryptoContainer";
import {Col, Container, Row} from "reactstrap";
import CryptoTableContainer from "../components/transactionHistoryTable/CryptoTableContainer";

interface IProps {
	dispatch?: any;
}

const TransactionHistory: React.FC<IProps> = (props: IProps) => {

	const [signUpModal, setSignUpModal] = useState();

	function toggleSignUpModal() {
		setSignUpModal(!signUpModal);
	}

	return (
		<CryptoContainer
			signUpModal={signUpModal}
			toggleSignUpModal={toggleSignUpModal}
		>
			<Container className="h-100">
				<Row>
					<Col xs={12} lg={6} className="py-3">
						<h1>Transaction History</h1>
						<span>This is a blurb of some sort. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</span>
					</Col>
				</Row>

				<Row>
					<Col xs={12}>
						<CryptoTableContainer />
					</Col>

				</Row>
			</Container>
		</CryptoContainer>

	);
};

export default connect()(TransactionHistory);
