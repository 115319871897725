import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {AdminApi, ChangeUserPasswordBody, User} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import getConfig from "../../../utils/getConfig";
import {useHistory} from "react-router";

interface IProps {
	isOpen: boolean;
	data: Object;
	token?: string;
	dispatch?: any;
	onClose(): void;
}

const BillProcessingModal: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const {isOpen, data} = props;

	function navigateToTransactionHistory() {
		history.push("/transaction-history");
	}

	function closeHelper(): void {
		props.onClose();
	}


	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			toggle={closeHelper}
			size="lg"
		>
			<ModalHeader
				toggle={closeHelper}
				className="border-0"
			>
				<span className="h3"><strong>Your Bill is Processing...</strong></span>
			</ModalHeader>

			<ModalBody>
				<div className="bill-processing-modal__container">
					<span>
						<strong>Payee: </strong>
						{data["payee"]}
					</span>
					<span>
						<strong>Account Number: </strong>
						{data["accountNumber"]}
					</span>
					<span>
						<strong>Amount (CAD): </strong>
						{data["amountCAD"]}
					</span>
					<span>
						<strong>Amount (BTC): </strong>
						{data["amountBTC"]}
					</span>
					<span>
						<strong>BTC Value at Transaction: </strong>
						{data["BTCValueAtTransaction"]}
					</span>
					<span>
						<strong>Fee: </strong>
						{data["fee"]}
					</span>
					<span>
						<strong>Total Charge: </strong>
						{data["totalCharge"]}
					</span>
				</div>
			</ModalBody>

			<ModalFooter className="border-0">
				<Button color="primary" onClick={navigateToTransactionHistory}>
					View Transaction History
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(BillProcessingModal)
