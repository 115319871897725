import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import BillTransactionTable from "./BillTransactionTable";
import CreditCardTransactionTable from "./CreditCardTransactionTable";
import {addError} from "../../redux/meta/MetaActions";
import {Transaction, TransactionsApi, TransactionType} from "client";
import getConfig from "../../utils/getConfig";
import {IStore} from "../../redux/defaultStore";
import {cloneDeep} from "lodash";

interface IProps {
	dispatch?: any;
	token?: any;
}

const CryptoTableContainer: React.FC<IProps> = (props: IProps) => {

	const [activeTab, setActiveTab] = useState(TransactionType.BILL);
	const [transactionList, setTransactionList] = useState<Transaction[]>([]);
	const [localOffset, setLocalOffset] = useState<number>(0);

	useEffect(() => {
		console.log(transactionList);
		setLocalOffset(0);
	}, [activeTab]);

	useEffect(() => {
		if (localOffset > 0) {
			getTransactions(false).then().catch();
		} else {
			getTransactions(true).then().catch();
		}
	}, [localOffset, activeTab]);

	async function getTransactions(pristine: boolean = true): Promise<void> {
		try {
			const res = await new TransactionsApi(getConfig(props.token)).userSearchForTransactions({
				transactionType: activeTab,
				limit: 50,
				offset: localOffset,
			});

			if (pristine) {
				setTransactionList(res?.transactions);
			} else {
				setTransactionList([...cloneDeep(transactionList), ...res?.transactions]);
			}

			if (res.paginationInfo && ((res.paginationInfo.limit + (res.paginationInfo.limit * res.paginationInfo.offset)) < res.paginationInfo.total)) {
				setLocalOffset(localOffset + 1);
			}

		} catch (err) {
			props.dispatch(addError(err));
		}
	}

	function setTabToBills(): void {
		setActiveTab(TransactionType.BILL);
	}

	function setTabToCreditCards(): void {
		setActiveTab(TransactionType.CREDITCARD);
	}

	return (
		<Container fluid={true} className="crypto-table">
			<Row>

				<Col xs={12} className="hr">
					<div className="crypto-table__container">
						<span
							className={`crypto-table__tab ${activeTab === TransactionType.BILL && "modal-card__tab--active"}`}
							onClick={setTabToBills}
							style={{cursor: "pointer"}}
						>
							Bill Transactions
						</span>
						<span
							className={`crypto-table__tab ${activeTab === TransactionType.CREDITCARD && "modal-card__tab--active"}`}
							style={{cursor: "pointer"}}
							onClick={setTabToCreditCards}
						>
							Credit Card Transactions
					</span>
					</div>
				</Col>
				<hr/>
			</Row>


			<Row>
				<Col>
					{activeTab === TransactionType.BILL ? (
						<BillTransactionTable data={transactionList}/>
					) : (
						<CreditCardTransactionTable data={transactionList}/>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
	}
})(CryptoTableContainer);
