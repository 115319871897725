import {Col, Row} from "reactstrap";
import CurrencyInput from "./inputs/CurrencyInput";
import * as React from "react";
import {useState} from "react";
import {Currency, TransactionsApi} from "client";
import {addError} from "../redux/meta/MetaActions";
import { connect } from "react-redux";

interface IProps {
	onChange: (amountCAD: number) => void;
	dispatch: any;
}

const queryTimes = [
	Date.now(),
]

const AmountInput: React.FC<IProps> = (props) => {
	const [amountCAD, setAmountCAD] = useState<string>();
	const [amountBTC, setAmountBTC] = useState<string>();
	const [amountDOGE, setAmountDOGE] = useState<string>();

	function createOnChange(currency: Currency, setFunction: (v: string) => void): (v: string) => Promise<void> {
		return async (amount: string) => {
			const requestTime = Date.now();
			setFunction(amount);
			try {

				if (Number(amount) === 0 || isNaN(Number(amount))) {
					setAmountCAD("0")
					setAmountBTC("0");
					setAmountDOGE("0");
					props.onChange(0);
					return;
				}

				switch(currency) {
					case Currency.CAD:
						setAmountCAD(amount);
						props.onChange(Number(amount));
						break;
					case Currency.BTC:
						setAmountBTC(amount);
						break;
					case Currency.DOGE:
						setAmountDOGE(amount);
						break;

				}


				if (isNaN(Number(amount))) {
					return;
				}

				// make request for prices
				const res = await new TransactionsApi().getPriceInformation({
					currency,
					amount: Number(amount),
				})

				// check if its the newest request
				if (queryTimes[queryTimes.length - 1] <= requestTime) {
					queryTimes.push(requestTime);

					switch(currency) {
						case Currency.CAD:
							setAmountBTC(res.BTC.toString());
							setAmountDOGE(res.DOGE.toString());
							break;
						case Currency.BTC:
							setAmountDOGE(res.DOGE.toString());
							setAmountCAD(res.CAD.toFixed(2));
							break;
						case Currency.DOGE:
							setAmountBTC(res.BTC.toString());
							setAmountCAD(res.CAD.toFixed(2));
							break;

					}

					props.onChange(res.CAD);
				}
			} catch (err) {
				console.error(err);
			}

		}
	}

	return (
		<Row className="align-items-end py-3">
			<Col xs={12} className="mb-3">
				<label>Bill Amount (in CAD)</label>
				<CurrencyInput
					name="amount"
					value={amountCAD}
					setValue={createOnChange(Currency.CAD, setAmountCAD)}
					placeholder="Amount..."
				/>
			</Col>
			<Col xs={12} className="mb-3">
				<label>Bill Amount (in BTC)</label>
				<CurrencyInput
					name="amountBTC"
					value={amountBTC}
					setValue={createOnChange(Currency.BTC, setAmountBTC)}
					placeholder="Amount..."
					append="BTC"
				/>
			</Col>
			<Col xs={12} className="mb-3">
				<label>Bill Amount (in DOGE)</label>
				<CurrencyInput
					name="amountDOGE"
					value={amountDOGE}
					setValue={createOnChange(Currency.DOGE, setAmountDOGE)}
					placeholder="Amount..."
					append="DOGE"
				/>
			</Col>
		</Row>
	)
}

export default connect()(AmountInput);
