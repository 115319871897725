import * as React from "react";
import CryptoHeader from "./header/CryptoHeader";

interface IProps {
	signUpModal: boolean;
	toggleSignUpModal: () => void;
}

const CryptoContainer: React.FC<IProps> = (props): JSX.Element => {
	return (
		<main>
			<CryptoHeader
				signUpModal={props.signUpModal}
				toggleSignUpModal={props.toggleSignUpModal}
			/>
			{props.children}
			<div style={{height: "50px"}}/>
		</main>
	);
}
export default CryptoContainer