import React from "react";
import InputNumber, {IInputNumberProps} from "./InputNumber";

interface IProps extends IInputNumberProps {
	value: any;
	setValue: any;
	prepend?: string;
	append?: string;
}

const CurrencyInput: React.FC<IProps> = (props): JSX.Element => {

	const {value, setValue} = props;

	return (
		<div className="currency-input">
			<div className="currency-input__prepend" style={{}}>
				<span className="text-white">{props.prepend}</span>
			</div>
			<InputNumber {...props} value={value} setValue={setValue} inputClasses="currency-input__input" />
			<div className="currency-input__append">
				<span className="text-white">{props.append}</span>
			</div>
		</div>
	);
}

CurrencyInput.defaultProps = {
	prepend: "$",
	append: "CAD"
}

export default CurrencyInput
