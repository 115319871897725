import * as React from "react";
import {DetailedHTMLProps, InputHTMLAttributes} from "react";

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
}

const SearchInput: React.FC<IProps> = (props): JSX.Element => {

	return (
		<input {...props}
		       className={`search-input ${props.className}`}
		       style={{
			       backgroundImage: `url(${process.env.PUBLIC_URL + "/icons/magnifying_glass_icon.svg"})`,
			       ...props.style,
		       }}
		       placeholder={props.placeholder}
		/>
	);
}

SearchInput.defaultProps = {
	placeholder: "Input search...",
	className: "",

}

export default SearchInput
