import * as React from "react";
import moment from "moment";
import {TableGenerator} from "frame-one-table";
import {Col, Container, Label, Row} from "reactstrap";
import SelectDropdown from "../../components/inputs/SelectDropdown";
import {useEffect, useState} from "react";
import {Transaction} from "client";
import {matchText} from "../../utils/variables";
import SearchInput from "../inputs/SearchInput";

interface IProps {
	data: Transaction[];
}

interface TempTransaction extends Transaction {
	formattedDate?: string;
}

const CreditCardTransactionTable: React.FC<IProps> = (props: IProps) => {

	const [dateValue, setDateValue] = useState<string>();
	const [searchValue, setSearchValue] = useState<string>();
	const [filteredList, setFilteredList] = useState<Transaction[]>();

	useEffect(() => {
		setFilteredList(props?.data);
	}, [props.data]);

	function formatDateValue(value: number): string {
		return moment(value).format("DD/MM/YYYY");
	}

	function textSearch(searchString): void {

		setSearchValue(searchString.target.value);

		const filteredArray: Transaction[] = (props.data).filter((item: TempTransaction) => {
			item.formattedDate = formatDateValue(item.creationDate);
			return matchText(item, searchString.target.value);
		});

		setFilteredList(filteredArray);
	}

	function searchDate(e) {
		setSearchValue("");
		const tempString = moment(e.target.value).format("DD/MM/YYYY");
		setDateValue(e.target.value);
		const filteredArray: TempTransaction[] = props.data.filter((item: TempTransaction) => {
			item.formattedDate = formatDateValue(item.creationDate);
			return matchText(item, tempString);
		});
		setFilteredList(filteredArray);
	}

	return (
		<Container>
			<Row className="pb-3">
				<Col xs={12} lg={5} className="pb-3">
					<Label>Find a Specific Transaction</Label>
					<SearchInput
						placeholder="Search For..."
						name="test"
						value={searchValue}
						onChange={textSearch}
					/>
				</Col>
				<Col xs={12} lg={4} className="pb-3">
					<Label>Select Time Period</Label>
					<input type="date" name="dateValue" value={dateValue} onChange={searchDate} placeholder="MM/YYYY..."/>
				</Col>
			</Row>
			<Row>
				<Col>
					<TableGenerator
						columnOptions={[
							{
								key: "creationDate",
								headerValue: "Date",
								showSortIcons: true,
								valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
							},
							{
								key: "status",
								headerValue: "Status",
								showSortIcons: true,
								cellRender: (value, row: Transaction, key, data,index) => {
									if (value.toLowerCase() === "pending") {
										return <span className="text-cyan">{value}</span>
									} else {
										return value;
									}
								}

							},
							{
								key: "company",
								headerValue: "Payee",
								showSortIcons: true,
							},
							{
								key: "creditCardNumber",
								headerValue: "Credit Card #",
								showSortIcons: true,
							},
							{
								key: "amountCAD",
								headerValue: "Amount (CAD)",
								showSortIcons: true,
								valueFormatter: (value) => `$${value?.toFixed(2)}`,
							},
							{
								key: "feePercentage",
								headerValue: "Fee",
								showSortIcons: true,
								valueFormatter: (value, row: Transaction) => `$${(value * row.amountCAD).toFixed(2)}`,
							},
							{
								key: "totalCharge",
								headerValue: "Total Charge",
								showSortIcons: true,
								valueFormatter: (value, row: Transaction) => `$${((row.feePercentage + 1) * row.amountCAD).toFixed(2)}`,
							},
							{
								key: "notes",
								headerValue: "Notes",
								showSortIcons: true,
							},
						]}

						data={filteredList}

						paginatorProps={
							{
								show: false,
								currentLimit: 1,
								limitOptions: [10, 20, 30],
								total: 10,
								onLimitChange(newLimit: number) {
									console.log("hello");
								},
								currentOffset: 0,
								availableOffsets: [0, 1, 2],
								onOffsetChange(newOffset: number) {
									console.log("????");
								}
							}
						}
						showSortIcons={true}
						showHeader={true}
						showBody={true}
					/>
				</Col>
			</Row>

		</Container>
	);
}

export default CreditCardTransactionTable;
