import * as React from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import {
	RequestForgotPasswordBody,
	ResetForgotPasswordBody,
	SignUpBody,
	UserApi,
} from "client";
import {ChangeEventHandler, useState} from "react";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	onClose(getNewData: boolean): void;
}

enum ModalStep {
	GET_EMAIL,
	NEW_PASSWORD,
	FINISHED,
}

const defaultForgotPassowrdForm: RequestForgotPasswordBody = {
	email: "",
};

const defaultConfirmChangeForm: ResetForgotPasswordBody = {
	forgotPasswordID: "",
	verificationCode: "",
	newPassword: "",
	confirmPassword: ""
};

/**
 * combination modal with 3 display modes.
 */
const PasswordRecoveryModal: React.FC<IProps> = (props: IProps) => {

	const {isOpen} = props;

	const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.GET_EMAIL);
	const [form, setForm] = useState(defaultForgotPassowrdForm);
	const [resetForm, setResetForm] = useState(defaultConfirmChangeForm);

	function closeHelper(): void {
		setForm(defaultForgotPassowrdForm);
		setResetForm(defaultConfirmChangeForm);
		setModalStep(0);
		props.onClose(false);
	}

	function createOnChange(key: keyof SignUpBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	function createResetFormOnChange(key: keyof ResetForgotPasswordBody, value?: string): ChangeEventHandler<HTMLInputElement> | any {
		if (!value) {
			return (e) => {
				setResetForm({
					...resetForm,
					[key]: e.target.value,
				});
			}
		} else {
			setResetForm({
				...resetForm,
				[key]: value,
			});
		}
	}

	async function submitRequestForgotPassword(e): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new UserApi().userRequestForgotPassword({requestForgotPasswordBody: form});

			createResetFormOnChange("forgotPasswordID", res.forgotPasswordID);
			setForm(defaultForgotPassowrdForm);

			setModalStep(modalStep + 1);
			props.dispatch(decrementLoading());
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	async function submitResetForgotPassword(e): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UserApi().userResetForgotPassword({resetForgotPasswordBody: resetForm});
			props.dispatch(decrementLoading());
			setForm(defaultForgotPassowrdForm);
			setModalStep(modalStep + 1);

		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			toggle={closeHelper}
			size="md"
		>
			<ModalHeader toggle={closeHelper} className="border-0"><span
				className="h3">Password Recovery</span></ModalHeader>

			{modalStep === ModalStep.GET_EMAIL && (
				<form onSubmit={submitRequestForgotPassword}>

					<ModalBody>
						<div>
							<Label>
								Email Address
							</Label>
							<input
								value={form.email}
								placeholder="Email address..."
								onChange={createOnChange("email")}
							/>
						</div>
					</ModalBody>

					<ModalFooter className="border-0 d-flex flex-column align-items-end">
						<span style={{width: "100%"}}>A verification email will be sent to this email address, please check your inbox.</span>
						<Button
							type="submit"
							color="blue"
							onSubmit={submitRequestForgotPassword}
						>
							Send Recovery Link
						</Button>
					</ModalFooter>

				</form>
			)}

			{modalStep === ModalStep.NEW_PASSWORD && (
				<form onSubmit={submitResetForgotPassword}>
					<ModalBody>

						<Label>New Password</Label>
						<input
							className="mb-3"
							type="password"
							value={resetForm.newPassword}
							placeholder="New Passowrd..."
							onChange={createResetFormOnChange("newPassword")}
						/>

						<Label>Confirm New Password</Label>
						<input
							className="mb-3"
							type="password"
							value={resetForm.confirmPassword}
							placeholder="Confirm New Password..."
							onChange={createResetFormOnChange("confirmPassword")}
						/>

						<Label>Verification Code</Label>
						<input
							className="mb-3"
							value={resetForm.verificationCode}
							placeholder="Verification Code..."
							onChange={createResetFormOnChange("verificationCode")}
						/>

					</ModalBody>

					<ModalFooter className="border-0 d-flex flex-column align-items-end">
						<span style={{width: "100%"}}>Enter your new password along with your verification code to set a new password.</span>
						<Button
							type="submit"
							color="blue"
							onSubmit={submitRequestForgotPassword}
						>
							Submit New Password
						</Button>
					</ModalFooter>

				</form>
			)}

			{modalStep === ModalStep.FINISHED && (
				<form onSubmit={closeHelper}>
					<ModalBody>
						<span style={{width: "100%"}}>Successfully changed password!</span>
					</ModalBody>

					<ModalFooter className="border-0 d-flex flex-column align-items-end">
						<Button
							color="blue"
							onClick={closeHelper}
						>
							Close
						</Button>
					</ModalFooter>

				</form>
			)}

		</Modal>
	);
}

export default connect((store: IStore, props: IProps) => {
		return {
			...props,
			token: store.metaStore.token,
		}
	}
)(PasswordRecoveryModal);
