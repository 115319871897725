import {connect} from "react-redux";
import React, {useState} from "react";
import {RouteProps, useHistory, useLocation} from "react-router";
import {
	Button,
	Collapse,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
	UncontrolledDropdown
} from "reactstrap";
import SignUpModal from "../modals/user/SignUpModal";
import LogInModal from "../modals/user/LogInModal";
import PasswordRecoveryModal from "../modals/user/PasswordRecoveryModal";
import {logout} from "../../redux/meta/MetaActions";
import {IoEllipsisHorizontalSharp} from "react-icons/all";
import EditEmailModal from "../modals/user/EditEmailModal";
import {IStore} from "../../redux/defaultStore";

interface IProps {
	token?: string;
	dispatch?: any;
	signUpModal: boolean;
	toggleSignUpModal: () => void;
}

enum CryptoRoutes {
	DASHBOARD = "/",
	TRANSACTION_HISTORY = "/transaction-history",
}

/**
 * Displays conditional header based on token information.
 */
const CryptoHeader: React.FC<RouteProps & IProps> = (props: RouteProps & IProps) => {

	const location = useLocation();
	const history = useHistory();
	const [logInModal, setLogInModal] = useState(false);
	const [passwordRecoveryModal, setPasswordRecoveryModal] = useState(false);

	const [editEmailModal, setEditEmailModal] = useState(false);

	const [isOpen, setIsOpen] = useState(false);

	function highlightLink(pathEnum): string {
		if (location.pathname === pathEnum) {
				return "text-blue";
		} else {
			return "text-muted";
		}
	}

	function navigateTo(destination: CryptoRoutes) {
		return (() => {
			history.push(destination);
		});
	}

	function toggleNavbar() {
		setIsOpen(!isOpen);
	}

	function toggleSignUpModal(): void {
		props.toggleSignUpModal();
	}

	function toggleLogInModal(): void {
		setLogInModal(!logInModal);
	}

	function togglePasswordRecoveryModal(): void {
		if (logInModal) {
			toggleLogInModal();
		}
		setPasswordRecoveryModal(!passwordRecoveryModal);
	}

	function toggleEditEmailModal() {
		setEditEmailModal(!editEmailModal);
	}

	function logoutButton() {
		props.dispatch(logout());
		history.replace("/");
		toggleNavbar();
	}

	return (
		<Container fluid={true} className={"crypto-header-container"}>
			<Container className="mb-4">

				<Navbar expand="md">

					<NavbarBrand onClick={navigateTo(CryptoRoutes.DASHBOARD)} >
						<img src="/logos/billcy_logo_horizontal_inverse.svg" alt="logo" style={{height: 50, cursor: "pointer"}}/>
					</NavbarBrand>

					<NavbarToggler>
						{props.token ? (
							<IoEllipsisHorizontalSharp onClick={toggleNavbar} size="2rem" className="opacity-50"/>
						) : (
							<NavLink
								className="custom-header-link"
								onClick={toggleLogInModal}
							>
								Log In
							</NavLink>
						)}
					</NavbarToggler>

					<Collapse isOpen={isOpen} navbar>
						<Nav navbar className="w-100 justify-content-end align-items-center">

							{props.token === undefined ? (
								<React.Fragment>

									<NavItem>
										<NavLink
											className={`custom-header-link text-muted`}
											onClick={toggleLogInModal}
										>
											Log In
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink>
											<Button
												color={"cyan"}
												onClick={toggleSignUpModal}
											>
												Make an Account!
											</Button>
										</NavLink>
									</NavItem>

								</React.Fragment>
							) : (
								<React.Fragment>

									<NavItem>
										<NavLink
											className={`custom-header-link ${highlightLink(CryptoRoutes.DASHBOARD)}`}
											onClick={navigateTo(CryptoRoutes.DASHBOARD)}
										>
											Pay Bills
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={`custom-header-link ${highlightLink(CryptoRoutes.TRANSACTION_HISTORY)}`}
											onClick={navigateTo(CryptoRoutes.TRANSACTION_HISTORY)}
										>
											Transaction History
										</NavLink>
									</NavItem>

									<UncontrolledDropdown
										nav
										inNavbar
										className="custom-header-link"
									>
										<DropdownToggle
											nav
											caret
											className="custom-header-link text-muted d-flex justify-content-center align-items-center"
										>
											More
										</DropdownToggle>

										<DropdownMenu>

											<DropdownItem onClick={toggleEditEmailModal}>
												Change Email Address
											</DropdownItem>

											{/*<DropdownItem>*/}
											{/*	Change Password*/}
											{/*</DropdownItem>*/}

											<DropdownItem onClick={logoutButton}>
												Log Out
											</DropdownItem>

										</DropdownMenu>

									</UncontrolledDropdown>
								</React.Fragment>
							)}

						</Nav>
					</Collapse>
				</Navbar>
			</Container>

			<LogInModal
				isOpen={logInModal}
				onClose={toggleLogInModal}
			>
				<>
					<Button
						color="cyan"
						onClick={toggleSignUpModal}
						className="d-block d-md-none"
					>
						Make an Account!
					</Button>
					<span className="d-block d-md-none">
						<strong>Don't have one yet? Make an account today!</strong>
					</span>
					<div className="d-block d-md-none hr"/>
					<span className="link color-cyan" onClick={togglePasswordRecoveryModal}>Password Recovery</span>
				</>
			</LogInModal>

			<PasswordRecoveryModal
				isOpen={passwordRecoveryModal}
				onClose={togglePasswordRecoveryModal}
			/>

			<SignUpModal
				isOpen={props.signUpModal}
				onClose={toggleSignUpModal}
			/>

			<EditEmailModal
				isOpen={editEmailModal}
				onClose={toggleEditEmailModal}
			/>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(CryptoHeader);
