import * as DetectBrowser from "detect-browser";

export function getBrowser() {
    try {
        return DetectBrowser.detect();
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export function matchText(obj, text): boolean {
    if (typeof obj === "string") {
        return (obj?.toLowerCase()).includes(text.toLowerCase());
    }
    if (typeof obj === "number") {
        return (obj?.toString()).indexOf(text) > -1;
    }
    if (typeof obj === "boolean") {
        return (obj?.toString().toLowerCase()).includes(text.toLowerCase());
    }
    if (typeof obj === "undefined") {
        return false;
    }
    return Object.values(obj).some(val => matchText(val, text));
}