import React from "react";
import {connect} from "react-redux";
import AccordionElement from "../accordian/AccordianElement";

interface IProps {
	dispatch?: any;
}

const accordionContent: any[] = [
	{
		title: "Q: Are there any fees?",
		content: "Yes, there is a 3% fee that will be charged for processing."
	},
	{
		title: "Q: How can I monitor the status?",
		content: "Our staff will update you via the Transaction History. You can check this area out once you've made an account."
	},
	{
		title: "Q: What happens if the bill cannot be processed?",
		content: "Your crypto will be refunded to the sending address."
	},
	{
		title: "Q: Do I need to submit my identity?",
		content: "No the only details we need are the company and/or the credit card number for processing. However if the payee is not expecting your payment, you might want to let them know ahead of time you are sending it to them."
	},
	{
		title: "Q: Do you track anything about me?",
		content: "The only things we store are your email address or contacting you and the few payment details provided."
	},
	{
		title: "Q: Can you do non-Canadian payees?",
		content: "Not at the current moment, however keep an eye out for the future."
	},
];

const FAQElement: React.FC<IProps> = (props: IProps) => {

	return (
		<div className="d-flex flex-column pt-5 align-items-center h-100">
			<div className="p-4 w-100">
				<img className="w-100" src={process.env.PUBLIC_URL + "/images/btc-ai.svg"} alt="placeholder"/>
			</div>
			<section>
				{accordionContent.map(AccordionElement)}
			</section>
		</div>
	);
};

export default connect()(FAQElement);
