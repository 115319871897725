import React, {useRef, useState} from "react";
import {FaChevronDown} from "react-icons/all";
import {$cyan} from "../../style/_colors";

interface IProps {
	title?: string,
	content?: any,
}

const AccordionElement: React.FC<IProps> = (props) => {

	const [activeState, setActiveState] = useState("");
	const [setHeight, setHeightState] = useState("0");
	const [setRotate, setRotateState] = useState("accordion__icon");

	const content = useRef(null);

	function toggleActiveState() {
		setActiveState(activeState === "" ? "active" : "");
		setHeightState(activeState === "active" ? "0" : `${content.current.scrollHeight}px`);
		setRotateState(activeState === "active" ? "accordion__icon" : "accordion__icon rotate");
	}

	return (
		<div key={`accordion-element-${Math.random()* 100}`} className="accordion__section">
			<button className={`accordion ${activeState}`} onClick={toggleActiveState}>
				<span className="accordion__title">{props.title}</span>
				<div className={`${setRotate}`}>
					<FaChevronDown color={activeState !== "" ? $cyan : undefined}/>
				</div>
			</button>
			<div ref={content} className="accordion__content" style={{ maxHeight: `${setHeight}` }}>
				<div className="accordion__hr"/>
				<div
					className="accordion__text"
					dangerouslySetInnerHTML={{ __html: props.content }}
				/>
			</div>
		</div>
	);
}

export default AccordionElement;