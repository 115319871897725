import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading, login} from "../../../redux/meta/MetaActions";
import {SignUpBody, UserApi, UserLoginBody} from "client";
import getConfig from "../../../utils/getConfig";

interface IProps {
	isOpen: boolean;
	token?: string;
	dispatch?: any;
	children?: JSX.Element;
	onClose(getNewData: boolean): void;
}

const defaultAddUserForm: UserLoginBody = {
	email: "",
	password: "",
};

/**
 * Modal with login modal. takes in children for conditional JSX rendering
 */
const LogInModal: React.FC<IProps> = (props ) => {

	const {isOpen} = props;
	const [form, setForm] = useState<UserLoginBody>(defaultAddUserForm);

	function closeHelper(): void {
		setForm(defaultAddUserForm);
		props.onClose(false);
	}

	function createOnChange(key: keyof SignUpBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	async function submitAddNewUser(e): Promise<void> {
		e.preventDefault();
		props.dispatch(incrementLoading());
		try {
			const res = await new UserApi(getConfig()).userLogin({userLoginBody: form});
			setForm(defaultAddUserForm);
			props.onClose(true);
			props.dispatch(login(res.token));
			props.dispatch(decrementLoading());
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			toggle={closeHelper}
			size="lg"
		>
			<ModalHeader
				toggle={closeHelper}
				className="border-0"
			>
				<span className="h3">Log In to your Account</span>
			</ModalHeader>

			<form onSubmit={submitAddNewUser}>
				<ModalBody>
					<div className="mb-3">
						<Label>
							Enter Email Address
						</Label>
						<input
							value={form.email}
							placeholder="Enter email address..."
							onChange={createOnChange("email")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Password*
						</Label>
						<input
							type="password"
							value={form.password}
							placeholder="Set a password..."
							onChange={createOnChange("password")}
						/>
					</div>

				</ModalBody>

				<ModalFooter className="d-flex flex-column-reverse flex-md-row border-0 justify-content-between align-items-center mb-3">

					{props.children}

					<Button
						type="submit"
						color="blue"
						onSubmit={submitAddNewUser}
					>
						Log In
					</Button>
				</ModalFooter>

			</form>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(LogInModal);
