import React from "react";
import {Label} from "reactstrap";
import { FiCheck } from "react-icons/fi";

export interface IInputCheckboxProps {
	name?: string;
	disabled?: boolean;
	value?: boolean;
	checkmarkClassName?: string;
	checkmarkColor?: string;
	onToggle?(value?: boolean): void;
}

const InputCheckbox: React.FC<IInputCheckboxProps> = (props): JSX.Element => {

	const {value, onToggle, disabled, checkmarkColor, checkmarkClassName} = props;

	const handleClick = () => {
		if (!disabled) {
			onToggle(!value);
		}
	};

	return (
		<div className="pr-3 text-left">
			<Label className="custom-checkbox">{props.children}
				<input type="checkbox" checked={value} onChange={handleClick} />
				<span className={`checkmark ${checkmarkClassName}`}>
                    <FiCheck size={"4rem"} color={checkmarkColor} className={!value ? "d-none" : ""} />
                </span>
			</Label>
		</div>
	);
}

InputCheckbox.defaultProps = {
	disabled: false,
	value: false,
	checkmarkClassName: "",
	checkmarkColor: "cyan",
}
export default InputCheckbox
