import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import {UpdateEmailBody, UserApi} from "client";
import getConfig from "../../../utils/getConfig";

interface IProps {
	isOpen: boolean;
	token?: string;
	dispatch?: any;

	onClose(getNewData: boolean): void;
}

enum ModalStep {
	NEW_EMAIL,
	FINISHED,
}

const defaultEditEmailForm: UpdateEmailBody = {
	email: "",
	password: "",
};

/**
 * Modal for changing user email
 */
const EditEmailModal: React.FC<IProps> = (props) => {

		const {isOpen} = props;
		const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.NEW_EMAIL);

		const [form, setForm] = useState<UpdateEmailBody>(defaultEditEmailForm);

		function closeHelper(): void {
			setForm(defaultEditEmailForm);
			props.onClose(false);
		}

		function createOnChange(key: keyof UpdateEmailBody): ChangeEventHandler<HTMLInputElement> {
			return (e) => {
				setForm({
					...form,
					[key]: e.target.value,
				});
			}
		}

		async function submitEditUserEmail(e): Promise<void> {
			e.preventDefault();
			props.dispatch(incrementLoading());
			try {
				await new UserApi(getConfig(props.token)).userUpdateEmail({updateEmailBody: form});
				setForm(defaultEditEmailForm);
				toggleModalStep();

				props.dispatch(decrementLoading());
			} catch (e) {
				props.dispatch(decrementLoading());
				props.dispatch(addError(e));
			}
		}

		function toggleModalStep() {
			setModalStep(modalStep + 1);
		}

		return (
			<Modal
				isOpen={isOpen}
				fade={true}
				centered={true}
				toggle={closeHelper}
				size="md"
			>
				<ModalHeader
					toggle={closeHelper}
					className="border-0"
				>
					<span className="h3">Edit Email</span>
				</ModalHeader>

				{modalStep === ModalStep.NEW_EMAIL && (
					<form onSubmit={submitEditUserEmail}>
						<ModalBody>
							<div className="mb-3">
								<Label>
									New Email Address
								</Label>
								<input
									value={form.email}
									placeholder="Enter email address..."
									onChange={createOnChange("email")}
								/>
							</div>

							<div className="mb-3">
								<Label>
									Password
								</Label>
								<input
									type="password"
									value={form.password}
									placeholder="Enter your password..."
									onChange={createOnChange("password")}
								/>
							</div>

						</ModalBody>

						<ModalFooter className="border-0 d-flex flex-column align-items-center align-items-md-end">
							<Button
								type="submit"
								color="blue"
								onSubmit={submitEditUserEmail}
							>
								Change Email Address
							</Button>
						</ModalFooter>

					</form>
				)}
				{modalStep === ModalStep.FINISHED && (
					<>
						<ModalBody>
							<span style={{width: "100%"}}>Successfully changed Email.</span>
						</ModalBody>
						<ModalFooter className="border-0 d-flex flex-column align-items-center align-items-md-end">
							<Button
								type="submit"
								color="blue"
								onClick={closeHelper}
							>
								Close
							</Button>
						</ModalFooter>
					</>

				)}
			</Modal>
		);
	}
;

export default connect((store: IStore, props: IProps) => {
		return {
			...props,
			token: store.metaStore.token,
		}
	}
)(EditEmailModal);
