import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface IProps {
	isOpen: boolean;
	data: any;
	token?: string;
	dispatch?: any;

	onClose(getNewData: boolean): void;

	callback(data: any): void;
}

export interface BillingInformation {
	payee: string,
	account: string,
	amount?: string,
	amountBTC?: string,
	isCreditCard: boolean,
}

const placeholderData = {
	payee: "Telus Communications",
	accountNumber: "1234-567-09089",
	amountCAD: "$150.00",
	amountBTC: "0.00320725",
	BTCValueAtTransaction: "$46769.00 CAD",
	fee: "$10.00 CAD",
	totalCharge: "$160.00 CAD",
}

/**
 * Modal with login modal. takes in children for conditional JSX rendering
 */
const ThirdPartyModal: React.FC<IProps> = (props) => {

	const {isOpen, onClose, data, callback} = props;

	console.log("data", data);

	function closeHelper(): void {
		onClose(false);
	}

	function submitCallback(e) {
		e.preventDefault();
		callback(placeholderData);
	}

	return (
		<Modal
			isOpen={isOpen}
			fade={true}
			centered={true}
			toggle={closeHelper}
			size="lg"
		>
			<form onSubmit={submitCallback}>
				<ModalHeader
					toggle={closeHelper}
					className="border-0"
				>
					<span className="h3">Third Party Modal</span>
				</ModalHeader>

				<ModalBody>
					<div/>
				</ModalBody>

				<ModalFooter>
					<Button
						onSubmit={submitCallback}
						color="blue"
					>
						Submit
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(ThirdPartyModal);
