import * as React from "react";
import {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import InputNumber from "../../inputs/InputNumber";
import {addError} from "../../../redux/meta/MetaActions";
import {connect} from "react-redux";
import AmountInput from "../../AmountInput";
import {CreateTransactionBody, TransactionType} from "client";
import CryptoAutoComplete from "../../inputs/CryptoAutoComplete";
import {autoCompleteCompanies} from "../../../utils/autoCompleteCompanies";

interface IProps {
	dispatch?: any;

	onSubmit(data: CreateTransactionBody): void;
}

const BillingForm: React.FC<IProps> = (props): JSX.Element => {

	const {onSubmit} = props;

	const [payee, setPayee] = useState([]);
	const [payeeTextValue, setPayeeTextValue] = useState();
	const [account, setAccount] = useState();
	const [amount, setAmount] = useState(0);
	const [notes, setNotes] = useState<string>();

	function onSubmitHelper() {
		try {
			onSubmit({
				transactionType: TransactionType.BILL,
				company: payee[0] === payeeTextValue ? payee[0] : payeeTextValue,
				accountNumber: account,
				amountCAD: amount,
				notes: notes ? notes : undefined,
			});
		} catch (err) {
			props.dispatch(addError({messages: ["Please Enter in your Billing information"]}));
		}
	}

	function setNotesHelper(e) {
		setNotes(e.target.value);
	}

	return (
		<form className="pt-3">
			<Row className="pb-3">
				<Col xs={12}>
					<label>Select A Company</label>
					<CryptoAutoComplete
						options={autoCompleteCompanies}
						placeholder="Select a New Payee..."
						selections={payee}
						setSelections={setPayee}
						inputValueOnChange={setPayeeTextValue}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12}>
					<label>Account Number</label>
					<InputNumber
						name="account"
						placeholder="Enter Account Number..."
						value={account}
						setValue={setAccount}
					/>
				</Col>
			</Row>

			<AmountInput onChange={setAmount}/>

			<Row className="pb-3">
				<Col xs={12}>
					<label>Notes</label>
					<textarea
						name="notes"
						placeholder="Additional Notes..."
						value={notes}
						onChange={setNotesHelper}
					/>
				</Col>
			</Row>

			{/*<Row className="align-items-end py-3">*/}
			{/*	<Col xs={12} md={6} className="mb-3">*/}
			{/*		<label>Bill Amount (in CAD)</label>*/}
			{/*		<CurrencyInput name="amount" value={amount} setValue={setAmount} placeholder="Amount..."/>*/}
			{/*	</Col>*/}
			{/*	<Col xs={12} md={6} className="mb-3">*/}
			{/*		<label>Bill Amount (in BTC)</label>*/}
			{/*		<CurrencyInput*/}
			{/*			name="amountBTC"*/}
			{/*			value={amountBTC}*/}
			{/*			setValue={setAmountBTC}*/}
			{/*			placeholder="Amount..."*/}
			{/*			append="BTC"*/}
			{/*		/>*/}
			{/*	</Col>*/}
			{/*	<Col xs={12} md={6} className="mb-3">*/}
			{/*		<label>Bill Amount (in DOGE)</label>*/}
			{/*		<CurrencyInput*/}
			{/*			name="amountDOGE"*/}
			{/*			value={amountDOGE}*/}
			{/*			setValue={setAmountDOGE}*/}
			{/*			placeholder="Amount..."*/}
			{/*			append="DOGE"*/}
			{/*		/>*/}
			{/*	</Col>*/}
			{/*</Row>*/}

			<Row>
				<Col className="d-flex justify-content-center justify-content-md-end">
					<Button
						color="blue"
						onClick={onSubmitHelper}
					>
						Pay Your Bill
					</Button>
				</Col>
			</Row>

		</form>
	);
}
export default connect()(BillingForm)
