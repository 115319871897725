import React, {useState} from "react";
import {connect} from "react-redux";

import CryptoContainer from "../components/CryptoContainer";
import {Col, Container, Row} from "reactstrap";
import ModalCard from "../components/modals/modalCard/ModalCard";
import FAQElement from "../components/elements/FAQElement";

interface IProps {
	dispatch?: any;
}

const DashboardHome: React.FC<IProps> = (props: IProps) => {
	const [signUpModal, setSignUpModal] = useState(false);

	function toggleSignUpModal() {
		setSignUpModal(!signUpModal);
	}

	return (
		<CryptoContainer
			signUpModal={signUpModal}
			toggleSignUpModal={toggleSignUpModal}
		>
			<Container>
				<Row >
					<Col xs={12} xl={6}>

						<header>
							<h1>Pay Bills</h1>
							<p>
								Pay any canadian company via crypto with no strings attached. <b>Operate your life decentralized!</b>
							</p>
						</header>

						<ModalCard
							toggleSignUpModal={toggleSignUpModal}
						/>
					</Col>
					<Col xs={12} xl={6}>
						<FAQElement/>
					</Col>
				</Row>
			</Container>
		</CryptoContainer>

	);
};

export default connect()(DashboardHome);
