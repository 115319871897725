import * as React from "react";
import {useState} from "react";
import {connect} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import {CreateTransactionBody, TransactionType} from "client";
import InputNumber from "../../inputs/InputNumber";
import {addError} from "../../../redux/meta/MetaActions";
import AmountInput from "../../AmountInput";
import CryptoAutoComplete from "../../inputs/CryptoAutoComplete";
import {autoCompleteCompanies} from "../../../utils/autoCompleteCompanies";

interface IProps {
	dispatch?: any;

	onSubmit(data: CreateTransactionBody): void;
}

const BillingCreditForm: React.FC<IProps> = (props): JSX.Element => {

	const {onSubmit} = props;
	const [payee, setPayee] = useState([]);
	const [payeeTextValue, setPayeeTextValue] = useState();
	const [creditCard, setCreditCard] = useState(undefined);
	const [amount, setAmount] = useState<number>(0);
	const [notes, setNotes] = useState<string>();

	function onSubmitHelper() {
		try {
			onSubmit({
				transactionType: TransactionType.CREDITCARD,
				company: payee[0] === payeeTextValue ? payee[0] : payeeTextValue,
				creditCardNumber: creditCard,
				amountCAD: amount,
			});
		} catch (err) {
			props.dispatch(addError({messages: ["Please Enter in your Billing information"]}));
		}
	}

	function setNotesHelper(e) {
		setNotes(e.target.value);
	}

	return (
		<form className="py-3">
			<Row>
				<Col xs={12}>
					<label>Select A Company</label>
					<CryptoAutoComplete
						options={autoCompleteCompanies}
						placeholder="Select a New Payee..."
						selections={payee}
						setSelections={setPayee}
						inputValueOnChange={setPayeeTextValue}
					/>
				</Col>
			</Row>

			<Row>
				<Col xs={12} className="mb-3">
					<label>Credit Card Number</label>
					<InputNumber
						name="creditCard"
						placeholder="Card Number..."
						value={creditCard}
						setValue={setCreditCard}
					/>
				</Col>
			</Row>

			<AmountInput onChange={setAmount}/>

			<Row style={{paddingBottom: 20}}>
				<Col xs={12}>
					<label>Notes</label>
					<textarea
						name="notes"
						placeholder="Additional Notes..."
						value={notes}
						onChange={setNotesHelper}
					/>
				</Col>
			</Row>

			<Row>
				<Col className="d-flex justify-content-center justify-content-md-end">
					<Button
						color="blue"
						onClick={onSubmitHelper}
					>
						Pay Your Bill
					</Button>
				</Col>
			</Row>

		</form>
	);
}
export default connect()(BillingCreditForm)
