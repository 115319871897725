import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import NotFoundPage from "./pages/NotFoundPage";
import DemoPage from "./pages/DemoPage";
import DashboardHome from "./pages/DashboardHome";
import TransactionHistory from "./pages/TransactionHistory";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ParticlesBg from "particles-bg";
import {$cyan} from "./style/_colors";

const App: React.FC = () => {

	return (
		<BrowserRouter>

			<ErrorManager/>
			<LoadingManager/>
			<TokenManager/>

				<main>
					<ParticlesBg
						type="cobweb"
						bg={true}
						color={$cyan}
					/>

					<Switch>
						<Route exact path="/" component={DashboardHome}/>
						<Route exact path="/test" component={DemoPage}/>
						<AuthenticatedRoute exact path="/transaction-history" component={TransactionHistory}/>
						<Route exact path="/404" component={NotFoundPage}/>
						<Redirect to="/404"/>
					</Switch>
				</main>
		</BrowserRouter>
	);
};

export default App;
