import {IAutoCompleteOption} from "../components/inputs/CryptoAutoComplete";

export const autoCompleteCompanies: IAutoCompleteOption[] = [
	{
		label: "4Refuel Canada LP",
		value: "4Refuel Canada LP",
	},
	{
		label: "A.R.C. Accounts Recovery Corporation",
		value: "A.R.C. Accounts Recovery Corporation",
	},
	{
		label: "AAFC AgriStability AgriInvest",
		value: "AAFC AgriStability AgriInvest",
	},
	{
		label: "Abell Pest Control",
		value: "Abell Pest Control",
	},
	{
		label: "Acanac Inc",
		value: "Acanac Inc",
	},
	{
		label: "Acricorp - CFFO - Farm Bus Reg",
		value: "Acricorp - CFFO - Farm Bus Reg",
	},
	{
		label: "Action Collections & Receivables Mgmt",
		value: "Action Collections & Receivables Mgmt",
	},
	{
		label: "ADT Security Services Canada Inc",
		value: "ADT Security Services Canada Inc",
	},
	{
		label: "AFBS - RRSP",
		value: "AFBS - RRSP",
	},
	{
		label: "AFBS - TFSA",
		value: "AFBS - TFSA",
	},
	{
		label: "AffGlo (Global Credit Collection)",
		value: "AffGlo (Global Credit Collection)",
	},
	{
		label: "Affirm Financial",
		value: "Affirm Financial",
	},
	{
		label: "Ag Energy Co-operative Ltd",
		value: "Ag Energy Co-operative Ltd",
	},
	{
		label: "Agco Finance Canada Ltd",
		value: "Agco Finance Canada Ltd",
	},
	{
		label: "Agracity Crop & Nutrition Ltd.",
		value: "Agracity Crop & Nutrition Ltd.",
	},
	{
		label: "Agricorp - Agristability - Ontario",
		value: "Agricorp - Agristability - Ontario",
	},
	{
		label: "Agricorp - FBR",
		value: "Agricorp - FBR",
	},
	{
		label: "Agricorp - Production Insurance",
		value: "Agricorp - Production Insurance",
	},
	{
		label: "Agricorp - RMP Grains and Oilseeds",
		value: "Agricorp - RMP Grains and Oilseeds",
	},
	{
		label: "Agricorp - RMP Livestock",
		value: "Agricorp - RMP Livestock",
	},
	{
		label: "Agris Co-Operative Ltd",
		value: "Agris Co-Operative Ltd",
	},
	{
		label: "Aim Professional Services Ltd",
		value: "Aim Professional Services Ltd",
	},
	{
		label: "Air Liquide Canada Inc",
		value: "Air Liquide Canada Inc",
	},
	{
		label: "Airdrie, City - Utilities",
		value: "Airdrie, City - Utilities",
	},
	{
		label: "Aktiv Kapital Acquisitions Inc",
		value: "Aktiv Kapital Acquisitions Inc",
	},
	{
		label: "AlarmForce Industries Inc.",
		value: "AlarmForce Industries Inc.",
	},
	{
		label: "Alectra Utilities Corporation",
		value: "Alectra Utilities Corporation",
	},
	{
		label: "Algoma Power Inc",
		value: "Algoma Power Inc",
	},
	{
		label: "Algoma University",
		value: "Algoma University",
	},
	{
		label: "Algonquin College - Tuition Fees",
		value: "Algonquin College - Tuition Fees",
	},
	{
		label: "Algonquin Highlands, Township of",
		value: "Algonquin Highlands, Township of",
	},
	{
		label: "All Communications Network Canada (ACN)",
		value: "All Communications Network Canada (ACN)",
	},
	{
		label: "AllCleared Solutions Inc",
		value: "AllCleared Solutions Inc",
	},
	{
		label: "Allied International Credit Corp",
		value: "Allied International Credit Corp",
	},
	{
		label: "Allstate Insurance",
		value: "Allstate Insurance",
	},
	{
		label: "Allstream",
		value: "Allstream",
	},
	{
		label: "Alternative Beauty Services",
		value: "Alternative Beauty Services",
	},
	{
		label: "Altima Telecom",
		value: "Altima Telecom",
	},
	{
		label: "American Express Optima including Amex LOC",
		value: "American Express Optima including Amex LOC",
	},
	{
		label: "American Express Regular Card",
		value: "American Express Regular Card",
	},
	{
		label: "Amherstburg Community Church",
		value: "Amherstburg Community Church",
	},
	{
		label: "Amherstburg Taxes",
		value: "Amherstburg Taxes",
	},
	{
		label: "Apple Financial Services - TDFS",
		value: "Apple Financial Services - TDFS",
	},
	{
		label: "Arbor Memorial Ont Prepaid Cemetary",
		value: "Arbor Memorial Ont Prepaid Cemetary",
	},
	{
		label: "ARM Collection Agency",
		value: "ARM Collection Agency",
	},
	{
		label: "Armour, Township - Property Tax",
		value: "Armour, Township - Property Tax",
	},
	{
		label: "ARO Inc",
		value: "ARO Inc",
	},
	{
		label: "Ashfield-Colborne-Wawanosh, Township - Taxes",
		value: "Ashfield-Colborne-Wawanosh, Township - Taxes",
	},
	{
		label: "ASI Computer Technologies Canada Corp",
		value: "ASI Computer Technologies Canada Corp",
	},
	{
		label: "Assante Capital Management Ltd",
		value: "Assante Capital Management Ltd",
	},
	{
		label: "ATCO Electric Yukon",
		value: "ATCO Electric Yukon",
	},
	{
		label: "Aviva - Home/Auto (via RBC Insurance)",
		value: "Aviva - Home/Auto (via RBC Insurance)",
	},
	{
		label: "Aviva Insurance - Home & Auto",
		value: "Aviva Insurance - Home & Auto",
	},
	{
		label: "Avivia Insurance - Business",
		value: "Avivia Insurance - Business",
	},
	{
		label: "Avon Canada Inc.",
		value: "Avon Canada Inc.",
	},
	{
		label: "Aylmer, Town - Property Tax",
		value: "Aylmer, Town - Property Tax",
	},
	{
		label: "Barrie, City - Taxes",
		value: "Barrie, City - Taxes",
	},
	{
		label: "Barrie, City - Water",
		value: "Barrie, City - Water",
	},
	{
		label: "Bayshore Home Health",
		value: "Bayshore Home Health",
	},
	{
		label: "Bayshore Specialty Rx",
		value: "Bayshore Specialty Rx",
	},
	{
		label: "BBS Securities Inc - Virtual Brokers",
		value: "BBS Securities Inc - Virtual Brokers",
	},
	{
		label: "BC Hydro",
		value: "BC Hydro",
	},
	{
		label: "BDO Canada LLP",
		value: "BDO Canada LLP",
	},
	{
		label: "Beach Grove Golf & Country Club Ltd.",
		value: "Beach Grove Golf & Country Club Ltd.",
	},
	{
		label: "Beauty Systems Group Canada",
		value: "Beauty Systems Group Canada",
	},
	{
		label: "Beer Store",
		value: "Beer Store",
	},
	{
		label: "Belairdirect - Ontario",
		value: "Belairdirect - Ontario",
	},
	{
		label: "Bell Aliant NB / NS / PE",
		value: "Bell Aliant NB / NS / PE",
	},
	{
		label: "Bell Aliant NL",
		value: "Bell Aliant NL",
	},
	{
		label: "Bell Canada (Incl. Bell One Bill)",
		value: "Bell Canada (Incl. Bell One Bill)",
	},
	{
		label: "Bell Conferencing",
		value: "Bell Conferencing",
	},
	{
		label: "Bell Expressvu",
		value: "Bell Expressvu",
	},
	{
		label: "Bell Mobility Cellular",
		value: "Bell Mobility Cellular",
	},
	{
		label: "Bell MTS Home Services- 9 Digits",
		value: "Bell MTS Home Services- 9 Digits",
	},
	{
		label: "Bell MTS Internet",
		value: "Bell MTS Internet",
	},
	{
		label: "Bell Smart Home",
		value: "Bell Smart Home",
	},
	{
		label: "Ben Moss Jewellers - TDFS",
		value: "Ben Moss Jewellers - TDFS",
	},
	{
		label: "Best Buy Desjardins",
		value: "Best Buy Desjardins",
	},
	{
		label: "Black River-Matheson, Twp - Property Tax",
		value: "Black River-Matheson, Twp - Property Tax",
	},
	{
		label: "Bluetone",
		value: "Bluetone",
	},
	{
		label: "Bluewater Power Dist. - Water/Sewer",
		value: "Bluewater Power Dist. - Water/Sewer",
	},
	{
		label: "Bluewater, Municipality - Taxes",
		value: "Bluewater, Municipality - Taxes",
	},
	{
		label: "Bluewater, Municipality - Utilities",
		value: "Bluewater, Municipality - Utilities",
	},
	{
		label: "BMO Investorline - Regular Contributions",
		value: "BMO Investorline - Regular Contributions",
	},
	{
		label: "BMO Investorline - Spousal Contributions",
		value: "BMO Investorline - Spousal Contributions",
	},
	{
		label: "BMO Life Assurance Company",
		value: "BMO Life Assurance Company",
	},
	{
		label: "BMO Nesbitt Burns - Dep. & Reg. Contr.",
		value: "BMO Nesbitt Burns - Dep. & Reg. Contr.",
	},
	{
		label: "Boardwalk Rental Communities",
		value: "Boardwalk Rental Communities",
	},
	{
		label: "Bombay - TDFS",
		value: "Bombay - TDFS",
	},
	{
		label: "Bond Street Collections",
		value: "Bond Street Collections",
	},
	{
		label: "Border Connect Inc",
		value: "Border Connect Inc",
	},
	{
		label: "Bowring - TDFS",
		value: "Bowring - TDFS",
	},
	{
		label: "Bracebridge, Town - Taxes",
		value: "Bracebridge, Town - Taxes",
	},
	{
		label: "Brampton Hydro",
		value: "Brampton Hydro",
	},
	{
		label: "Brampton Taxes",
		value: "Brampton Taxes",
	},
	{
		label: "Brescia University College",
		value: "Brescia University College",
	},
	{
		label: "Brentwood College School",
		value: "Brentwood College School",
	},
	{
		label: "Briarlane Rental Property Management Inc",
		value: "Briarlane Rental Property Management Inc",
	},
	{
		label: "Brick Card (TDFS)",
		value: "Brick Card (TDFS)",
	},
	{
		label: "Brick Card (WFFRS)",
		value: "Brick Card (WFFRS)",
	},
	{
		label: "Brim Financial",
		value: "Brim Financial",
	},
	{
		label: "Brock University - Tuition & Residence",
		value: "Brock University - Tuition & Residence",
	},
	{
		label: "Bruce Telecom",
		value: "Bruce Telecom",
	},
	{
		label: "Burlington Hydro",
		value: "Burlington Hydro",
	},
	{
		label: "Burlington, City - Taxes",
		value: "Burlington, City - Taxes",
	},
	{
		label: "Business Depot",
		value: "Business Depot",
	},
	{
		label: "C.S.T. Consultants Inc",
		value: "C.S.T. Consultants Inc",
	},
	{
		label: "CAA Insurance Company",
		value: "CAA Insurance Company",
	},
	{
		label: "CAA North & East Ontario",
		value: "CAA North & East Ontario",
	},
	{
		label: "CAA South Central Ontario",
		value: "CAA South Central Ontario",
	},
	{
		label: "Call Select Inc.",
		value: "Call Select Inc.",
	},
	{
		label: "Cambrian College - Student Payments",
		value: "Cambrian College - Student Payments",
	},
	{
		label: "Cambridge & North Dumphries Hydro",
		value: "Cambridge & North Dumphries Hydro",
	},
	{
		label: "Cambridge, City - Property Tax",
		value: "Cambridge, City - Property Tax",
	},
	{
		label: "Cambridge, City - Water and Sewer",
		value: "Cambridge, City - Water and Sewer",
	},
	{
		label: "Canaccede International Management Ltd",
		value: "Canaccede International Management Ltd",
	},
	{
		label: "Canaccord Genuity Corp.",
		value: "Canaccord Genuity Corp.",
	},
	{
		label: "Canada Apprentice Loan Program",
		value: "Canada Apprentice Loan Program",
	},
	{
		label: "Canada Border Services Agency",
		value: "Canada Border Services Agency",
	},
	{
		label: "Canada Bread Ontario",
		value: "Canada Bread Ontario",
	},
	{
		label: "Canada Life - Insurance Premium",
		value: "Canada Life - Insurance Premium",
	},
	{
		label: "Canadian Bar Insurance Association (CBIA)",
		value: "Canadian Bar Insurance Association (CBIA)",
	},
	{
		label: "Canadian Forex Limited",
		value: "Canadian Forex Limited",
	},
	{
		label: "Canadian Springs Div of Aquaterra Corp",
		value: "Canadian Springs Div of Aquaterra Corp",
	},
	{
		label: "Canadian Tire Acceptance",
		value: "Canadian Tire Acceptance",
	},
	{
		label: "Canadian Tire Line of Credit",
		value: "Canadian Tire Line of Credit",
	},
	{
		label: "Canadian Union of Skilled Workers (CUSW)",
		value: "Canadian Union of Skilled Workers (CUSW)",
	},
	{
		label: "Canscribe Career College",
		value: "Canscribe Career College",
	},
	{
		label: "Canwel Building Materials Ltd",
		value: "Canwel Building Materials Ltd",
	},
	{
		label: "CAP REIT Limited Partnership",
		value: "CAP REIT Limited Partnership",
	},
	{
		label: "Capital One Security Funds",
		value: "Capital One Security Funds",
	},
	{
		label: "Cardinal Couriers Ltd",
		value: "Cardinal Couriers Ltd",
	},
	{
		label: "CareCredit (GE Capital)",
		value: "CareCredit (GE Capital)",
	},
	{
		label: "CareRX Ontario",
		value: "CareRX Ontario",
	},
	{
		label: "Carfinco",
		value: "Carfinco",
	},
	{
		label: "Carleton University",
		value: "Carleton University",
	},
	{
		label: "Carma Corp",
		value: "Carma Corp",
	},
	{
		label: "Carpenters & Joiners Local 494",
		value: "Carpenters & Joiners Local 494",
	},
	{
		label: "Carry Telecom Inc",
		value: "Carry Telecom Inc",
	},
	{
		label: "Case N Drum Oil LP",
		value: "Case N Drum Oil LP",
	},
	{
		label: "CBV Collection Services Ltd.",
		value: "CBV Collection Services Ltd.",
	},
	{
		label: "Central Huron, Municipality - Taxes",
		value: "Central Huron, Municipality - Taxes",
	},
	{
		label: "Central Manitoulin, Munc - Taxes",
		value: "Central Manitoulin, Munc - Taxes",
	},
	{
		label: "Ceridian Canada Ltd",
		value: "Ceridian Canada Ltd",
	},
	{
		label: "Certas Direct Insurance Company",
		value: "Certas Direct Insurance Company",
	},
	{
		label: "Certas Home & Auto Insurance Company",
		value: "Certas Home & Auto Insurance Company",
	},
	{
		label: "Charm Diamond Centres - TDFS",
		value: "Charm Diamond Centres - TDFS",
	},
	{
		label: "Chatham-Kent Corporation - Taxes",
		value: "Chatham-Kent Corporation - Taxes",
	},
	{
		label: "Chetwynd, District - Property Tax",
		value: "Chetwynd, District - Property Tax",
	},
	{
		label: "Chetwynd, District - Utilities",
		value: "Chetwynd, District - Utilities",
	},
	{
		label: "Chieftan Insurance",
		value: "Chieftan Insurance",
	},
	{
		label: "CIBC National Student Loan Centre",
		value: "CIBC National Student Loan Centre",
	},
	{
		label: "CIGL Group Benefits Ottawa",
		value: "CIGL Group Benefits Ottawa",
	},
	{
		label: "Citi Cards MC - 603527",
		value: "Citi Cards MC - 603527",
	},
	{
		label: "Citibank Corporate Card",
		value: "Citibank Corporate Card",
	},
	{
		label: "Citizenship & Immigration Canada - Loans",
		value: "Citizenship & Immigration Canada - Loans",
	},
	{
		label: "Cityfone Telecommunications",
		value: "Cityfone Telecommunications",
	},
	{
		label: "CNH Capital Canada - 6035",
		value: "CNH Capital Canada - 6035",
	},
	{
		label: "CNH Capital Revolving Citi Cards 7824",
		value: "CNH Capital Revolving Citi Cards 7824",
	},
	{
		label: "Coachman Insurance Company",
		value: "Coachman Insurance Company",
	},
	{
		label: "Coca-Cola Canada Bottling Ltd",
		value: "Coca-Cola Canada Bottling Ltd",
	},
	{
		label: "Cogeco Connexion (Starts with 50) - ONT",
		value: "Cogeco Connexion (Starts with 50) - ONT",
	},
	{
		label: "Cold Lake - Utilities, City of",
		value: "Cold Lake - Utilities, City of",
	},
	{
		label: "Cole International Inc",
		value: "Cole International Inc",
	},
	{
		label: "College of Registered Phsycotherapist-ON",
		value: "College of Registered Phsycotherapist-ON",
	},
	{
		label: "Collingwood, - Town - Property Tax",
		value: "Collingwood, - Town - Property Tax",
	},
	{
		label: "Commercial Credit Adjusters",
		value: "Commercial Credit Adjusters",
	},
	{
		label: "Common Collection Agency Inc",
		value: "Common Collection Agency Inc",
	},
	{
		label: "Community Living Essex County",
		value: "Community Living Essex County",
	},
	{
		label: "ComparAction Long Distance",
		value: "ComparAction Long Distance",
	},
	{
		label: "Compassion Canada",
		value: "Compassion Canada",
	},
	{
		label: "Comwave",
		value: "Comwave",
	},
	{
		label: "Conestoga College",
		value: "Conestoga College",
	},
	{
		label: "Conrad Grebel Univ College - Residence",
		value: "Conrad Grebel Univ College - Residence",
	},
	{
		label: "Contact Resource Services Inc",
		value: "Contact Resource Services Inc",
	},
	{
		label: "Convergia Networks Inc",
		value: "Convergia Networks Inc",
	},
	{
		label: "Co-Operators Insurance - Auto",
		value: "Co-Operators Insurance - Auto",
	},
	{
		label: "Co-Operators Insurance - Home",
		value: "Co-Operators Insurance - Home",
	},
	{
		label: "Co-operators Life Insurance Company",
		value: "Co-operators Life Insurance Company",
	},
	{
		label: "Costco Citi Commerce Solutions",
		value: "Costco Citi Commerce Solutions",
	},
	{
		label: "Costco Membership Renewal",
		value: "Costco Membership Renewal",
	},
	{
		label: "Counter Force Inc",
		value: "Counter Force Inc",
	},
	{
		label: "Cowan Insurance Group Ltd",
		value: "Cowan Insurance Group Ltd",
	},
	{
		label: "CRA 2019 Personal Tax Return",
		value: "CRA 2019 Personal Tax Return",
	},
	{
		label: "CRA 2020 Personal Tax Installments",
		value: "CRA 2020 Personal Tax Installments",
	},
	{
		label: "CRA Personal Tax Amount Owing (Arrears)",
		value: "CRA Personal Tax Amount Owing (Arrears)",
	},
	{
		label: "CRA (revenue) - Canada Child Tax Benefits",
		value: "CRA (revenue) - Canada Child Tax Benefits",
	},
	{
		label: "CRA Canada Emergency Benefit Repayment",
		value: "CRA Canada Emergency Benefit Repayment",
	},
	{
		label: "Craig McDonald Reddon Insurance Brokers",
		value: "Craig McDonald Reddon Insurance Brokers",
	},
	{
		label: "Credential Direct",
		value: "Credential Direct",
	},
	{
		label: "Credit Bureau Collections St Catherines",
		value: "Credit Bureau Collections St Catherines",
	},
	{
		label: "Credit Canada Debt Solutions Inc.",
		value: "Credit Canada Debt Solutions Inc.",
	},
	{
		label: "Credit Counselling Society",
		value: "Credit Counselling Society",
	},
	{
		label: "Credit Medical Corporation",
		value: "Credit Medical Corporation",
	},
	{
		label: "Credit Risk Management",
		value: "Credit Risk Management",
	},
	{
		label: "Creemore Springs Brewery Ltd",
		value: "Creemore Springs Brewery Ltd",
	},
	{
		label: "CTL Recovery",
		value: "CTL Recovery",
	},
	{
		label: "Culligan Water",
		value: "Culligan Water",
	},
	{
		label: "CUMIS Home and Auto Insurance",
		value: "CUMIS Home and Auto Insurance",
	},
	{
		label: "Cumis Life Insurance Company",
		value: "Cumis Life Insurance Company",
	},
	{
		label: "Custom House Currency Exchange",
		value: "Custom House Currency Exchange",
	},
	{
		label: "CWB National Leasing",
		value: "CWB National Leasing",
	},
	{
		label: "Cycles Lambert Inc",
		value: "Cycles Lambert Inc",
	},
	{
		label: "D & A Collection Corporation",
		value: "D & A Collection Corporation",
	},
	{
		label: "Dawn Euphemia, Township - Taxes",
		value: "Dawn Euphemia, Township - Taxes",
	},
	{
		label: "Debt Control Agency (DCA)",
		value: "Debt Control Agency (DCA)",
	},
	{
		label: "Deli Meats Limited",
		value: "Deli Meats Limited",
	},
	{
		label: "Dell Computer Corporation",
		value: "Dell Computer Corporation",
	},
	{
		label: "Deloitte LLP",
		value: "Deloitte LLP",
	},
	{
		label: "Delta Chi (Early Childhood Centre)",
		value: "Delta Chi (Early Childhood Centre)",
	},
	{
		label: "Delta Power Equipment Ltd",
		value: "Delta Power Equipment Ltd",
	},
	{
		label: "Desjardins Financial Security (DFS)",
		value: "Desjardins Financial Security (DFS)",
	},
	{
		label: "Desjardins Financing Principal Payment",
		value: "Desjardins Financing Principal Payment",
	},
	{
		label: "Diallog Telecommunications",
		value: "Diallog Telecommunications",
	},
	{
		label: "Diners Club",
		value: "Diners Club",
	},
	{
		label: "Direct Energy Business",
		value: "Direct Energy Business",
	},
	{
		label: "Direct Energy",
		value: "Direct Energy",
	},
	{
		label: "Direct Energy Regulated Services",
		value: "Direct Energy Regulated Services",
	},
	{
		label: "Distributel Communications Inc.",
		value: "Distributel Communications Inc.",
	},
	{
		label: "Dominion of Canada General Insurance Co",
		value: "Dominion of Canada General Insurance Co",
	},
	{
		label: "Dowler Karn Limited",
		value: "Dowler Karn Limited",
	},
	{
		label: "Durham Water - Region of",
		value: "Durham Water - Region of",
	},
	{
		label: "Dutton / Dunwich Water Department",
		value: "Dutton / Dunwich Water Department",
	},
	{
		label: "Dutton / Dunwich, Municipality - Taxes",
		value: "Dutton / Dunwich, Municipality - Taxes",
	},
	{
		label: "Dynacare",
		value: "Dynacare",
	},
	{
		label: "E.L.K. Energy",
		value: "E.L.K. Energy",
	},
	{
		label: "Ear Falls, Township - Property Tax",
		value: "Ear Falls, Township - Property Tax",
	},
	{
		label: "East Gwillimbury Taxes",
		value: "East Gwillimbury Taxes",
	},
	{
		label: "Eastlink",
		value: "Eastlink",
	},
	{
		label: "Easyfinancial Services Inc",
		value: "Easyfinancial Services Inc",
	},
	{
		label: "Easyvoice Telecom",
		value: "Easyvoice Telecom",
	},
	{
		label: "Echelon Insurance",
		value: "Echelon Insurance",
	},
	{
		label: "Economical Mutual Insurance Company",
		value: "Economical Mutual Insurance Company",
	},
	{
		label: "Edpro Energy Group Inc",
		value: "Edpro Energy Group Inc",
	},
	{
		label: "Edward Jones",
		value: "Edward Jones",
	},
	{
		label: "EF Educational Tours",
		value: "EF Educational Tours",
	},
	{
		label: "Electrical Safety Authority (ESA)",
		value: "Electrical Safety Authority (ESA)",
	},
	{
		label: "Electrozad Supply",
		value: "Electrozad Supply",
	},
	{
		label: "Elite Insurance Company",
		value: "Elite Insurance Company",
	},
	{
		label: "Empire Life - Group Plan Policy",
		value: "Empire Life - Group Plan Policy",
	},
	{
		label: "Empire Life - Individual Plan",
		value: "Empire Life - Individual Plan",
	},
	{
		label: "Employment and Social Development Canada",
		value: "Employment and Social Development Canada",
	},
	{
		label: "Enbridge Gas Inc",
		value: "Enbridge Gas Inc",
	},
	{
		label: "Enbridge Gas Inc. (Large Acct)",
		value: "Enbridge Gas Inc. (Large Acct)",
	},
	{
		label: "Enbridge Gas Inc. (Union Gas)",
		value: "Enbridge Gas Inc. (Union Gas)",
	},
	{
		label: "EnerCare Connections",
		value: "EnerCare Connections",
	},
	{
		label: "Enercare Home Services",
		value: "Enercare Home Services",
	},
	{
		label: "Energie NB Power",
		value: "Energie NB Power",
	},
	{
		label: "Energy Source",
		value: "Energy Source",
	},
	{
		label: "Enersource Hydro Mississauga",
		value: "Enersource Hydro Mississauga",
	},
	{
		label: "Engineers Canada/GWL Financial Security",
		value: "Engineers Canada/GWL Financial Security",
	},
	{
		label: "Enmax",
		value: "Enmax",
	},
	{
		label: "Entegrus Powerlines Inc",
		value: "Entegrus Powerlines Inc",
	},
	{
		label: "Enwin Utilities",
		value: "Enwin Utilities",
	},
	{
		label: "EOS Canada",
		value: "EOS Canada",
	},
	{
		label: "EPCOR",
		value: "EPCOR",
	},
	{
		label: "Epcor Electricity Distribution Ontario",
		value: "Epcor Electricity Distribution Ontario",
	},
	{
		label: "Epcor Natural Gas LP (Ontario)",
		value: "Epcor Natural Gas LP (Ontario)",
	},
	{
		label: "Equitable Life - Individual Life & CI",
		value: "Equitable Life - Individual Life & CI",
	},
	{
		label: "Equitable Life Savings Plan",
		value: "Equitable Life Savings Plan",
	},
	{
		label: "Erie Shores Golf & Country Club",
		value: "Erie Shores Golf & Country Club",
	},
	{
		label: "Erie Thames Power",
		value: "Erie Thames Power",
	},
	{
		label: "ERTH Solutions",
		value: "ERTH Solutions",
	},
	{
		label: "Essex Golf & Country Club",
		value: "Essex Golf & Country Club",
	},
	{
		label: "Essex Gospel Community Church",
		value: "Essex Gospel Community Church",
	},
	{
		label: "Essex Powerlines",
		value: "Essex Powerlines",
	},
	{
		label: "Essex, Town - A/R Misc",
		value: "Essex, Town - A/R Misc",
	},
	{
		label: "Essex, Town - Taxes",
		value: "Essex, Town - Taxes",
	},
	{
		label: "Esso Business Card (Imperial Oil)",
		value: "Esso Business Card (Imperial Oil)",
	},
	{
		label: "Esso Gift Card",
		value: "Esso Gift Card",
	},
	{
		label: "Execulink Telecom",
		value: "Execulink Telecom",
	},
	{
		label: "Exel Telecommunications",
		value: "Exel Telecommunications",
	},
	{
		label: "Express Scripts Canada Pharmacy",
		value: "Express Scripts Canada Pharmacy",
	},
	{
		label: "Express Toll Route 407",
		value: "Express Toll Route 407",
	},
	{
		label: "Fairstone",
		value: "Fairstone",
	},
	{
		label: "Fairstone-Credit Cards",
		value: "Fairstone-Credit Cards",
	},
	{
		label: "Faithlife Financial",
		value: "Faithlife Financial",
	},
	{
		label: "Family Responsibility Office Ontario",
		value: "Family Responsibility Office Ontario",
	},
	{
		label: "Fanshawe College - Residence",
		value: "Fanshawe College - Residence",
	},
	{
		label: "Fanshawe College - Tuition",
		value: "Fanshawe College - Tuition",
	},
	{
		label: "Farm Credit Canada (FCC)",
		value: "Farm Credit Canada (FCC)",
	},
	{
		label: "Federated Insurance Company of Canada",
		value: "Federated Insurance Company of Canada",
	},
	{
		label: "FedEx Express Canada",
		value: "FedEx Express Canada",
	},
	{
		label: "FIDO",
		value: "FIDO",
	},
	{
		label: "Financeit Canada Inc",
		value: "Financeit Canada Inc",
	},
	{
		label: "Financial Debt Recovery Limited",
		value: "Financial Debt Recovery Limited",
	},
	{
		label: "First Insurance Funding of Canada Inc",
		value: "First Insurance Funding of Canada Inc",
	},
	{
		label: "Flanagan Food Service Inc",
		value: "Flanagan Food Service Inc",
	},
	{
		label: "Fleming College - Residence",
		value: "Fleming College - Residence",
	},
	{
		label: "Fleming College - Tuition",
		value: "Fleming College - Tuition",
	},
	{
		label: "Flexity Financial",
		value: "Flexity Financial",
	},
	{
		label: "Ford Credit",
		value: "Ford Credit",
	},
	{
		label: "Form & Build Supply",
		value: "Form & Build Supply",
	},
	{
		label: "Fort Erie Taxes - Town of",
		value: "Fort Erie Taxes - Town of",
	},
	{
		label: "Fort Erie Water - Town of",
		value: "Fort Erie Water - Town of",
	},
	{
		label: "Forty Mile No 8, County - Property Tax",
		value: "Forty Mile No 8, County - Property Tax",
	},
	{
		label: "Freedom Mobile (formerly WIND Mobile)",
		value: "Freedom Mobile (formerly WIND Mobile)",
	},
	{
		label: "Freedom Pet Supplies Inc",
		value: "Freedom Pet Supplies Inc",
	},
	{
		label: "Freeds of Windsor",
		value: "Freeds of Windsor",
	},
	{
		label: "Fundstream/Fundscrip",
		value: "Fundstream/Fundscrip",
	},
	{
		label: "Future Shop Desjardins",
		value: "Future Shop Desjardins",
	},
	{
		label: "Future Steel Buildings",
		value: "Future Steel Buildings",
	},
	{
		label: "Gambles Ontario Product Inc",
		value: "Gambles Ontario Product Inc",
	},
	{
		label: "Gatestone & Co. Contact Inc",
		value: "Gatestone & Co. Contact Inc",
	},
	{
		label: "Gatestone & Co. Inc.",
		value: "Gatestone & Co. Inc.",
	},
	{
		label: "GE Money - GE Canadian Dealers",
		value: "GE Money - GE Canadian Dealers",
	},
	{
		label: "GE Money - Lowes Canada Commercial",
		value: "GE Money - Lowes Canada Commercial",
	},
	{
		label: "General Bank of Canada",
		value: "General Bank of Canada",
	},
	{
		label: "General Credit Services",
		value: "General Credit Services",
	},
	{
		label: "George Brown Coll-Current Year Tuition",
		value: "George Brown Coll-Current Year Tuition",
	},
	{
		label: "Georgian College - Payment Tuition",
		value: "Georgian College - Payment Tuition",
	},
	{
		label: "Gesco Limited Partnership - Shnier",
		value: "Gesco Limited Partnership - Shnier",
	},
	{
		label: "GFL Environmental Inc (Ontario)",
		value: "GFL Environmental Inc (Ontario)",
	},
	{
		label: "Girl Guides of Canada - Ontario Council",
		value: "Girl Guides of Canada - Ontario Council",
	},
	{
		label: "Globe & Mail",
		value: "Globe & Mail",
	},
	{
		label: "GM Financial - 9 Digit Account",
		value: "GM Financial - 9 Digit Account",
	},
	{
		label: "Gordon Food Service Canada Ltd",
		value: "Gordon Food Service Canada Ltd",
	},
	{
		label: "Gore Mutual Insurance Company",
		value: "Gore Mutual Insurance Company",
	},
	{
		label: "Gosfield North Communications Co-op Ltd.",
		value: "Gosfield North Communications Co-op Ltd.",
	},
	{
		label: "Graham Energy Limited",
		value: "Graham Energy Limited",
	},
	{
		label: "Grand & Toy Ltd.",
		value: "Grand & Toy Ltd.",
	},
	{
		label: "Great Northern Insulation",
		value: "Great Northern Insulation",
	},
	{
		label: "Greater Sudbury, City - Taxes",
		value: "Greater Sudbury, City - Taxes",
	},
	{
		label: "Great-West Group RRSP",
		value: "Great-West Group RRSP",
	},
	{
		label: "Great-West Group TFSA",
		value: "Great-West Group TFSA",
	},
	{
		label: "Great-West Insurance - Premium",
		value: "Great-West Insurance - Premium",
	},
	{
		label: "Green Shield Canada",
		value: "Green Shield Canada",
	},
	{
		label: "GreenLawn",
		value: "GreenLawn",
	},
	{
		label: "GSU - Greater Sudbury Utilities",
		value: "GSU - Greater Sudbury Utilities",
	},
	{
		label: "Guardian Security Systems (Vancouver)",
		value: "Guardian Security Systems (Vancouver)",
	},
	{
		label: "Guelph Hydro",
		value: "Guelph Hydro",
	},
	{
		label: "H.B. Group Insurance - Basic",
		value: "H.B. Group Insurance - Basic",
	},
	{
		label: "Hagerty Canada, LLC",
		value: "Hagerty Canada, LLC",
	},
	{
		label: "Haldimand County Taxes/Town of Haldimand",
		value: "Haldimand County Taxes/Town of Haldimand",
	},
	{
		label: "Halton Court Services Collections",
		value: "Halton Court Services Collections",
	},
	{
		label: "Hamilton, City - Taxes",
		value: "Hamilton, City - Taxes",
	},
	{
		label: "Harbour Insurance Services Ltd",
		value: "Harbour Insurance Services Ltd",
	},
	{
		label: "Harley-Davidson Financial Srv Canada",
		value: "Harley-Davidson Financial Srv Canada",
	},
	{
		label: "Hastings Highland, Municipality - Taxes",
		value: "Hastings Highland, Municipality - Taxes",
	},
	{
		label: "Hay Communications Co-operative Ltd",
		value: "Hay Communications Co-operative Ltd",
	},
	{
		label: "Hay Mutual Insurance Company",
		value: "Hay Mutual Insurance Company",
	},
	{
		label: "HDFSC Commercial",
		value: "HDFSC Commercial",
	},
	{
		label: "Healthcare of Ontario Pension Plan HOOPP",
		value: "Healthcare of Ontario Pension Plan HOOPP",
	},
	{
		label: "Heartland Farm Mutual",
		value: "Heartland Farm Mutual",
	},
	{
		label: "Hensall District Co-operative",
		value: "Hensall District Co-operative",
	},
	{
		label: "Heritage Educational Foundation",
		value: "Heritage Educational Foundation",
	},
	{
		label: "Heritage Park Alliance Church",
		value: "Heritage Park Alliance Church",
	},
	{
		label: "HIFX Canada Inc",
		value: "HIFX Canada Inc",
	},
	{
		label: "Highlands East, Municipality - Taxes",
		value: "Highlands East, Municipality - Taxes",
	},
	{
		label: "Hogan Pharmacy Partners Ltd",
		value: "Hogan Pharmacy Partners Ltd",
	},
	{
		label: "HollisWealth-Division of IAS",
		value: "HollisWealth-Division of IAS",
	},
	{
		label: "Home Card (PWBank)",
		value: "Home Card (PWBank)",
	},
	{
		label: "Home Depot CCCSC",
		value: "Home Depot CCCSC",
	},
	{
		label: "Home Hardware (WFFRS)",
		value: "Home Hardware (WFFRS)",
	},
	{
		label: "Homestead Land Holdings Ltd.",
		value: "Homestead Land Holdings Ltd.",
	},
	{
		label: "Honda Financial Services",
		value: "Honda Financial Services",
	},
	{
		label: "Horizon Utilities",
		value: "Horizon Utilities",
	},
	{
		label: "Howard Mutual Insurance Company",
		value: "Howard Mutual Insurance Company",
	},
	{
		label: "HSBC Finance",
		value: "HSBC Finance",
	},
	{
		label: "Hub Capital",
		value: "Hub Capital",
	},
	{
		label: "Hub Financial Inc",
		value: "Hub Financial Inc",
	},
	{
		label: "Hub International Ontario Ltd",
		value: "Hub International Ontario Ltd",
	},
	{
		label: "Hudson's Bay Credit Card",
		value: "Hudson's Bay Credit Card",
	},
	{
		label: "Huron Bay Co-op Inc",
		value: "Huron Bay Co-op Inc",
	},
	{
		label: "Huron University College Tuition",
		value: "Huron University College Tuition",
	},
	{
		label: "Husky Oil",
		value: "Husky Oil",
	},
	{
		label: "Hydro One Networks/Remote Communities",
		value: "Hydro One Networks/Remote Communities",
	},
	{
		label: "Hydro Ottawa Limited / Energy Ottawa",
		value: "Hydro Ottawa Limited / Energy Ottawa",
	},
	{
		label: "Hydro Quebec",
		value: "Hydro Quebec",
	},
	{
		label: "iA Auto Finance Inc",
		value: "iA Auto Finance Inc",
	},
	{
		label: "IBEW Local Union 353 - Dues",
		value: "IBEW Local Union 353 - Dues",
	},
	{
		label: "ICS Courier",
		value: "ICS Courier",
	},
	{
		label: "Ikea - Citi Cards",
		value: "Ikea - Citi Cards",
	},
	{
		label: "Impact Telecom",
		value: "Impact Telecom",
	},
	{
		label: "Industrial Alliance Ins & Financial Serv",
		value: "Industrial Alliance Ins & Financial Serv",
	},
	{
		label: "Industrial Alliance Securities",
		value: "Industrial Alliance Securities",
	},
	{
		label: "Ingersoll, Town - Property Tax",
		value: "Ingersoll, Town - Property Tax",
	},
	{
		label: "Ingram Micro",
		value: "Ingram Micro",
	},
	{
		label: "Intact Insurance Company",
		value: "Intact Insurance Company",
	},
	{
		label: "Interactive Brokers Canada Inc",
		value: "Interactive Brokers Canada Inc",
	},
	{
		label: "Investia Financial Services Inc",
		value: "Investia Financial Services Inc",
	},
	{
		label: "Investors Group Securities Inc - 9737",
		value: "Investors Group Securities Inc - 9737",
	},
	{
		label: "Ivari",
		value: "Ivari",
	},
	{
		label: "J.P. Morgan Chase Bank, N.A.",
		value: "J.P. Morgan Chase Bank, N.A.",
	},
	{
		label: "Jack Smith Fuels Ltd",
		value: "Jack Smith Fuels Ltd",
	},
	{
		label: "JDC.ca Inc",
		value: "JDC.ca Inc",
	},
	{
		label: "JEVCO Insurance Company",
		value: "JEVCO Insurance Company",
	},
	{
		label: "Jim Moore Petroleum",
		value: "Jim Moore Petroleum",
	},
	{
		label: "Jocelyn, Township - Taxes",
		value: "Jocelyn, Township - Taxes",
	},
	{
		label: "John Deere Financial (Installment / Lease)",
		value: "John Deere Financial (Installment / Lease)",
	},
	{
		label: "John Deere Multi-Use",
		value: "John Deere Multi-Use",
	},
	{
		label: "Johnson Specialty Insurance Solutions",
		value: "Johnson Specialty Insurance Solutions",
	},
	{
		label: "Just Energy Alberta LP",
		value: "Just Energy Alberta LP",
	},
	{
		label: "JYSK Benefits Plus Credit Card",
		value: "JYSK Benefits Plus Credit Card",
	},
	{
		label: "Kamsel Leasing",
		value: "Kamsel Leasing",
	},
	{
		label: "Kearney Planters",
		value: "Kearney Planters",
	},
	{
		label: "Kearney, Town - Taxes",
		value: "Kearney, Town - Taxes",
	},
	{
		label: "Keil-Dadson Insurance Brokers Ltd",
		value: "Keil-Dadson Insurance Brokers Ltd",
	},
	{
		label: "Kelcom Voice and Data",
		value: "Kelcom Voice and Data",
	},
	{
		label: "Kent & Essex Mutual Insurance Company",
		value: "Kent & Essex Mutual Insurance Company",
	},
	{
		label: "Kente Property Management",
		value: "Kente Property Management",
	},
	{
		label: "King's University College at UWO",
		value: "King's University College at UWO",
	},
	{
		label: "Kingsville Golf & Country Club",
		value: "Kingsville Golf & Country Club",
	},
	{
		label: "Kingsville, Town - Taxes",
		value: "Kingsville, Town - Taxes",
	},
	{
		label: "Kingsville, Town - Water",
		value: "Kingsville, Town - Water",
	},
	{
		label: "Kitchener - Wilmot Hydro",
		value: "Kitchener - Wilmot Hydro",
	},
	{
		label: "Kitchener Taxes",
		value: "Kitchener Taxes",
	},
	{
		label: "Kitchener Water & Gas",
		value: "Kitchener Water & Gas",
	},
	{
		label: "Knightsbridge Foreign Exchange",
		value: "Knightsbridge Foreign Exchange",
	},
	{
		label: "Koodo (formerly PC Mobile)",
		value: "Koodo (formerly PC Mobile)",
	},
	{
		label: "KOODO Mobile",
		value: "KOODO Mobile",
	},
	{
		label: "Lakehead University",
		value: "Lakehead University",
	},
	{
		label: "LakePoint Church",
		value: "LakePoint Church",
	},
	{
		label: "Lakeshore Taxes, Town of",
		value: "Lakeshore Taxes, Town of",
	},
	{
		label: "Lakeshore Water",
		value: "Lakeshore Water",
	},
	{
		label: "Lambton College",
		value: "Lambton College",
	},
	{
		label: "Lambton Shores, Mun - Taxes",
		value: "Lambton Shores, Mun - Taxes",
	},
	{
		label: "Lambton Shores, Mun - Utilities",
		value: "Lambton Shores, Mun - Utilities",
	},
	{
		label: "Lant Insurance Brokers",
		value: "Lant Insurance Brokers",
	},
	{
		label: "LaSalle, Town - Taxes",
		value: "LaSalle, Town - Taxes",
	},
	{
		label: "LaSalle, Town - Water / Sewer",
		value: "LaSalle, Town - Water / Sewer",
	},
	{
		label: "Laurentian Bank of Canada - LOC",
		value: "Laurentian Bank of Canada - LOC",
	},
	{
		label: "Laurentian University",
		value: "Laurentian University",
	},
	{
		label: "Law Society of Upper Canada",
		value: "Law Society of Upper Canada",
	},
	{
		label: "Leamington Taxes",
		value: "Leamington Taxes",
	},
	{
		label: "Legacy TD Financing Services",
		value: "Legacy TD Financing Services",
	},
	{
		label: "Legal Aid Ontario",
		value: "Legal Aid Ontario",
	},
	{
		label: "LifeLabs Medical Laboratory Services",
		value: "LifeLabs Medical Laboratory Services",
	},
	{
		label: "Lillooet, District - Property Tax",
		value: "Lillooet, District - Property Tax",
	},
	{
		label: "Linens-N-Things Canada",
		value: "Linens-N-Things Canada",
	},
	{
		label: "Livingston International Inc",
		value: "Livingston International Inc",
	},
	{
		label: "London Health Science Centre - Patient",
		value: "London Health Science Centre - Patient",
	},
	{
		label: "London Hydro",
		value: "London Hydro",
	},
	{
		label: "London Life - Insurance Loan",
		value: "London Life - Insurance Loan",
	},
	{
		label: "London Life - Insurance Premium",
		value: "London Life - Insurance Premium",
	},
	{
		label: "London Taxes, City of",
		value: "London Taxes, City of",
	},
	{
		label: "Long & McQuade Ltd Musical Instruments",
		value: "Long & McQuade Ltd Musical Instruments",
	},
	{
		label: "Lowe's Canada",
		value: "Lowe's Canada",
	},
	{
		label: "Loyalist College",
		value: "Loyalist College",
	},
	{
		label: "LSI Enterprises Canada ULC",
		value: "LSI Enterprises Canada ULC",
	},
	{
		label: "LTP Sports Group Inc",
		value: "LTP Sports Group Inc",
	},
	{
		label: "Lubrico Warranty Inc",
		value: "Lubrico Warranty Inc",
	},
	{
		label: "Luxury Jewellery Class (LJC) - TDFS",
		value: "Luxury Jewellery Class (LJC) - TDFS",
	},
	{
		label: "MAC Financial Recovery",
		value: "MAC Financial Recovery",
	},
	{
		label: "Machar, Township - Taxes",
		value: "Machar, Township - Taxes",
	},
	{
		label: "Madawaska Valley, Township - Taxes",
		value: "Madawaska Valley, Township - Taxes",
	},
	{
		label: "Magnetawan, Municipality - Taxes",
		value: "Magnetawan, Municipality - Taxes",
	},
	{
		label: "Maizex Seeds Inc",
		value: "Maizex Seeds Inc",
	},
	{
		label: "Malahide, Township - Taxes",
		value: "Malahide, Township - Taxes",
	},
	{
		label: "Manion Wilkins & Associates Ltd",
		value: "Manion Wilkins & Associates Ltd",
	},
	{
		label: "Manitoba Hydro - 1 Digit Account",
		value: "Manitoba Hydro - 1 Digit Account",
	},
	{
		label: "Manulife Affinity Health Insurance",
		value: "Manulife Affinity Health Insurance",
	},
	{
		label: "Manulife Individual Insurance",
		value: "Manulife Individual Insurance",
	},
	{
		label: "Manulife One",
		value: "Manulife One",
	},
	{
		label: "Manulife Securities Incorporated",
		value: "Manulife Securities Incorporated",
	},
	{
		label: "Manulife Securities Investment Services",
		value: "Manulife Securities Investment Services",
	},
	{
		label: "Maple Call Inc",
		value: "Maple Call Inc",
	},
	{
		label: "Mappins - TDFS",
		value: "Mappins - TDFS",
	},
	{
		label: "Mappins (Citi Cards)",
		value: "Mappins (Citi Cards)",
	},
	{
		label: "Marda Management Inc",
		value: "Marda Management Inc",
	},
	{
		label: "Markham, City - Taxes",
		value: "Markham, City - Taxes",
	},
	{
		label: "Mastercard - Bank of America",
		value: "Mastercard - Bank of America",
	},
	{
		label: "MasterCard - Bank of China (Canada)",
		value: "MasterCard - Bank of China (Canada)",
	},
	{
		label: "Mastercard - BMO",
		value: "Mastercard - BMO",
	},
	{
		label: "Mastercard - BMO Corporate/Commercial",
		value: "Mastercard - BMO Corporate/Commercial",
	},
	{
		label: "Mastercard - BMO Prepaid Travel",
		value: "Mastercard - BMO Prepaid Travel",
	},
	{
		label: "Mastercard - Canadian Tire",
		value: "Mastercard - Canadian Tire",
	},
	{
		label: "Mastercard - Canadian Tire Commercial",
		value: "Mastercard - Canadian Tire Commercial",
	},
	{
		label: "Mastercard - Capital One",
		value: "Mastercard - Capital One",
	},
	{
		label: "Mastercard - CIBC",
		value: "Mastercard - CIBC",
	},
	{
		label: "Mastercard - Collabria Canadian Dollar",
		value: "Mastercard - Collabria Canadian Dollar",
	},
	{
		label: "Mastercard - Desjardins",
		value: "Mastercard - Desjardins",
	},
	{
		label: "Mastercard - Fidem",
		value: "Mastercard - Fidem",
	},
	{
		label: "Mastercard - Fleetcor Canada",
		value: "Mastercard - Fleetcor Canada",
	},
	{
		label: "Mastercard - HorizonPlus",
		value: "Mastercard - HorizonPlus",
	},
	{
		label: "Mastercard - HSBC",
		value: "Mastercard - HSBC",
	},
	{
		label: "Mastercard - Hudson's Bay",
		value: "Mastercard - Hudson's Bay",
	},
	{
		label: "Mastercard - MBNA Canada",
		value: "Mastercard - MBNA Canada",
	},
	{
		label: "Mastercard - National Bank",
		value: "Mastercard - National Bank",
	},
	{
		label: "Mastercard - NextWave Prepaid",
		value: "Mastercard - NextWave Prepaid",
	},
	{
		label: "Mastercard - PC Financial",
		value: "Mastercard - PC Financial",
	},
	{
		label: "Mastercard - Peoples Trust",
		value: "Mastercard - Peoples Trust",
	},
	{
		label: "Mastercard - RBC Royal Bank",
		value: "Mastercard - RBC Royal Bank",
	},
	{
		label: "Mastercard - Rogers Bank",
		value: "Mastercard - Rogers Bank",
	},
	{
		label: "Mastercard - Scotiabank",
		value: "Mastercard - Scotiabank",
	},
	{
		label: "Mastercard - Sears Canada Bank",
		value: "Mastercard - Sears Canada Bank",
	},
	{
		label: "Mastercard - Servus",
		value: "Mastercard - Servus",
	},
	{
		label: "Mastercard - Shell (Bank of Montreal)",
		value: "Mastercard - Shell (Bank of Montreal)",
	},
	{
		label: "Mastercard - Tangerine",
		value: "Mastercard - Tangerine",
	},
	{
		label: "Mastercard - TD",
		value: "Mastercard - TD",
	},
	{
		label: "Mastercard - Triangle",
		value: "Mastercard - Triangle",
	},
	{
		label: "Mastercard - Walmart",
		value: "Mastercard - Walmart",
	},
	{
		label: "Max Canada Insurance Company",
		value: "Max Canada Insurance Company",
	},
	{
		label: "Maxium Financial Services Inc.",
		value: "Maxium Financial Services Inc.",
	},
	{
		label: "MBNA Canada Consumer Finance",
		value: "MBNA Canada Consumer Finance",
	},
	{
		label: "McDougall Energy Inc",
		value: "McDougall Energy Inc",
	},
	{
		label: "McDougall, Municipality - Taxes",
		value: "McDougall, Municipality - Taxes",
	},
	{
		label: "McFarlan Rowlans Insurance Brokers Inc.",
		value: "McFarlan Rowlans Insurance Brokers Inc.",
	},
	{
		label: "McGill University",
		value: "McGill University",
	},
	{
		label: "McMaster University",
		value: "McMaster University",
	},
	{
		label: "McMurich/Monteith, Twp - Taxes",
		value: "McMurich/Monteith, Twp - Taxes",
	},
	{
		label: "McNab / Braeside, Twp - Property Tax",
		value: "McNab / Braeside, Twp - Property Tax",
	},
	{
		label: "McTague Law Firm",
		value: "McTague Law Firm",
	},
	{
		label: "Medical Pharmacies Group",
		value: "Medical Pharmacies Group",
	},
	{
		label: "Medicard Finance",
		value: "Medicard Finance",
	},
	{
		label: "Medisystem Pharmacy Ontario",
		value: "Medisystem Pharmacy Ontario",
	},
	{
		label: "Memorial University of Newfoundland",
		value: "Memorial University of Newfoundland",
	},
	{
		label: "Mercedes-Benz Financial",
		value: "Mercedes-Benz Financial",
	},
	{
		label: "Metropolitan Credit Adjusters",
		value: "Metropolitan Credit Adjusters",
	},
	{
		label: "Michael Hill Jeweller (Canada) Ltd",
		value: "Michael Hill Jeweller (Canada) Ltd",
	},
	{
		label: "Milton Hydro",
		value: "Milton Hydro",
	},
	{
		label: "Minto - Rental Division Ottawa",
		value: "Minto - Rental Division Ottawa",
	},
	{
		label: "Mission, District of - Taxes",
		value: "Mission, District of - Taxes",
	},
	{
		label: "Mississauga Taxes",
		value: "Mississauga Taxes",
	},
	{
		label: "MJR Capital Services Inc",
		value: "MJR Capital Services Inc",
	},
	{
		label: "MNSI Telecom",
		value: "MNSI Telecom",
	},
	{
		label: "Mogo Financial Inc",
		value: "Mogo Financial Inc",
	},
	{
		label: "Mohawk College",
		value: "Mohawk College",
	},
	{
		label: "Moore Propane Ltd",
		value: "Moore Propane Ltd",
	},
	{
		label: "Mornington Communications Co-op Ltd",
		value: "Mornington Communications Co-op Ltd",
	},
	{
		label: "Muskoka Lakes, Township - Property Tax",
		value: "Muskoka Lakes, Township - Property Tax",
	},
	{
		label: "National Bank (prev WF Mortgages)",
		value: "National Bank (prev WF Mortgages)",
	},
	{
		label: "National Bank Financial Inc",
		value: "National Bank Financial Inc",
	},
	{
		label: "National Credit Recovery Inc",
		value: "National Credit Recovery Inc",
	},
	{
		label: "National HealthClaim Corporation",
		value: "National HealthClaim Corporation",
	},
	{
		label: "National Home Services",
		value: "National Home Services",
	},
	{
		label: "National Student Loan Services Centre",
		value: "National Student Loan Services Centre",
	},
	{
		label: "NBIN - National Bank Independent Network",
		value: "NBIN - National Bank Independent Network",
	},
	{
		label: "NEBS Payroll Service Limited",
		value: "NEBS Payroll Service Limited",
	},
	{
		label: "Nedco Ontario",
		value: "Nedco Ontario",
	},
	{
		label: "New North Fuels",
		value: "New North Fuels",
	},
	{
		label: "New Tecumseth, Town - Taxes",
		value: "New Tecumseth, Town - Taxes",
	},
	{
		label: "Newbury, Village - Taxes",
		value: "Newbury, Village - Taxes",
	},
	{
		label: "Nexicom Telecommunications Inc",
		value: "Nexicom Telecommunications Inc",
	},
	{
		label: "Nextstep Great-West Life",
		value: "Nextstep Great-West Life",
	},
	{
		label: "NFP Canada Corp",
		value: "NFP Canada Corp",
	},
	{
		label: "Niagara College of Applied Arts & Tech",
		value: "Niagara College of Applied Arts & Tech",
	},
	{
		label: "Niagara College NOTL Residence",
		value: "Niagara College NOTL Residence",
	},
	{
		label: "Niagara Falls Taxes",
		value: "Niagara Falls Taxes",
	},
	{
		label: "Niagara Peninsula Energy Inc",
		value: "Niagara Peninsula Energy Inc",
	},
	{
		label: "Nipissing University",
		value: "Nipissing University",
	},
	{
		label: "Nissan Canada Financial Services Inc",
		value: "Nissan Canada Financial Services Inc",
	},
	{
		label: "Norfolk County Taxes",
		value: "Norfolk County Taxes",
	},
	{
		label: "North Bay Hydro",
		value: "North Bay Hydro",
	},
	{
		label: "North Kent Mutual",
		value: "North Kent Mutual",
	},
	{
		label: "Northbridge General Insurance Corp",
		value: "Northbridge General Insurance Corp",
	},
	{
		label: "Northern Bruce Peninsula, Mun - Taxes",
		value: "Northern Bruce Peninsula, Mun - Taxes",
	},
	{
		label: "Northern Tel",
		value: "Northern Tel",
	},
	{
		label: "Northwestel Inc.",
		value: "Northwestel Inc.",
	},
	{
		label: "Nova Scotia Power",
		value: "Nova Scotia Power",
	},
	{
		label: "Novex Insurance Company",
		value: "Novex Insurance Company",
	},
	{
		label: "OACETT",
		value: "OACETT",
	},
	{
		label: "OCAD University",
		value: "OCAD University",
	},
	{
		label: "Okanagan College",
		value: "Okanagan College",
	},
	{
		label: "Old Oak Properties Inc",
		value: "Old Oak Properties Inc",
	},
	{
		label: "Olympia Trust Company - Registered Plans",
		value: "Olympia Trust Company - Registered Plans",
	},
	{
		label: "Oncorp Direct",
		value: "Oncorp Direct",
	},
	{
		label: "OnSource - Ontario Hydro",
		value: "OnSource - Ontario Hydro",
	},
	{
		label: "Ontario College Application Services (OCAS)",
		value: "Ontario College Application Services (OCAS)",
	},
	{
		label: "Ontario College of Teachers",
		value: "Ontario College of Teachers",
	},
	{
		label: "Ontario College of Trades",
		value: "Ontario College of Trades",
	},
	{
		label: "Ontario Natural Food Coop",
		value: "Ontario Natural Food Coop",
	},
	{
		label: "Ontario Teachers Pension Plan",
		value: "Ontario Teachers Pension Plan",
	},
	{
		label: "Ontario Universities Application Centre",
		value: "Ontario Universities Application Centre",
	},
	{
		label: "Ontera",
		value: "Ontera",
	},
	{
		label: "OPSEU Pension Trust (OPTRUST)",
		value: "OPSEU Pension Trust (OPTRUST)",
	},
	{
		label: "Orangeville Hydro",
		value: "Orangeville Hydro",
	},
	{
		label: "Orangeville Taxes (Town of)",
		value: "Orangeville Taxes (Town of)",
	},
	{
		label: "Orillia Taxes",
		value: "Orillia Taxes",
	},
	{
		label: "Orkin Canada",
		value: "Orkin Canada",
	},
	{
		label: "Oro-Medonte, Township Taxes",
		value: "Oro-Medonte, Township Taxes",
	},
	{
		label: "Oro-Medonte, Township Taxes",
		value: "Oro-Medonte, Township Taxes",
	},
	{
		label: "Orr Insurance Brokers Inc (Stratford)",
		value: "Orr Insurance Brokers Inc (Stratford)",
	},
	{
		label: "Oshawa PUC Networks Inc",
		value: "Oshawa PUC Networks Inc",
	},
	{
		label: "Oshawa Taxes",
		value: "Oshawa Taxes",
	},
	{
		label: "Ottawa Taxes",
		value: "Ottawa Taxes",
	},
	{
		label: "Outdoor Gear Canada",
		value: "Outdoor Gear Canada",
	},
	{
		label: "Parasource Marketing & Distribution",
		value: "Parasource Marketing & Distribution",
	},
	{
		label: "Park Property Management Inc.",
		value: "Park Property Management Inc.",
	},
	{
		label: "Parkwood Gospel Temple of Windsor",
		value: "Parkwood Gospel Temple of Windsor",
	},
	{
		label: "Parry Sount, Town - Taxes",
		value: "Parry Sount, Town - Taxes",
	},
	{
		label: "Partners in Credit Inc",
		value: "Partners in Credit Inc",
	},
	{
		label: "PaymentEvolution Corporation",
		value: "PaymentEvolution Corporation",
	},
	{
		label: "Payprop Canada Limited",
		value: "Payprop Canada Limited",
	},
	{
		label: "Payworks Inc",
		value: "Payworks Inc",
	},
	{
		label: "Pearson Dunn Insurance",
		value: "Pearson Dunn Insurance",
	},
	{
		label: "Peel, Region - Water & Wastewater Service",
		value: "Peel, Region - Water & Wastewater Service",
	},
	{
		label: "Pelee, Township - Property Tax",
		value: "Pelee, Township - Property Tax",
	},
	{
		label: "Pembridge Insurance Company",
		value: "Pembridge Insurance Company",
	},
	{
		label: "Pentecostal Assemblies of Canada",
		value: "Pentecostal Assemblies of Canada",
	},
	{
		label: "Peoples - TDFS",
		value: "Peoples - TDFS",
	},
	{
		label: "Peoples (Citi Cards)",
		value: "Peoples (Citi Cards)",
	},
	{
		label: "Pepsi Bottling Group",
		value: "Pepsi Bottling Group",
	},
	{
		label: "Petawawa, Town - Taxes",
		value: "Petawawa, Town - Taxes",
	},
	{
		label: "Petro Canada Super Pass",
		value: "Petro Canada Super Pass",
	},
	{
		label: "Petrolia, Town - Taxes",
		value: "Petrolia, Town - Taxes",
	},
	{
		label: "Petroline Petro (McDougall Energy Inc)",
		value: "Petroline Petro (McDougall Energy Inc)",
	},
	{
		label: "Pioneer Energy",
		value: "Pioneer Energy",
	},
	{
		label: "Pitney Works",
		value: "Pitney Works",
	},
	{
		label: "Plan International Canada Inc",
		value: "Plan International Canada Inc",
	},
	{
		label: "PlayPay Inc",
		value: "PlayPay Inc",
	},
	{
		label: "Plummer, Township - Taxes",
		value: "Plummer, Township - Taxes",
	},
	{
		label: "Pointe West Golf Club",
		value: "Pointe West Golf Club",
	},
	{
		label: "Popp Parete Russo Leno LLP, Accountants",
		value: "Popp Parete Russo Leno LLP, Accountants",
	},
	{
		label: "Postmedia Network Inc - Adertising",
		value: "Postmedia Network Inc - Adertising",
	},
	{
		label: "Power Source Canada Ltd",
		value: "Power Source Canada Ltd",
	},
	{
		label: "PowerStream Energy Services Inc.",
		value: "PowerStream Energy Services Inc.",
	},
	{
		label: "Powrmatic of Canada",
		value: "Powrmatic of Canada",
	},
	{
		label: "Poynt360 Inc",
		value: "Poynt360 Inc",
	},
	{
		label: "PRA Group",
		value: "PRA Group",
	},
	{
		label: "Praxair Distribution Inc",
		value: "Praxair Distribution Inc",
	},
	{
		label: "Preferred One",
		value: "Preferred One",
	},
	{
		label: "Premier Tech Aqua",
		value: "Premier Tech Aqua",
	},
	{
		label: "Primerica Life Insurance Co. of Canada",
		value: "Primerica Life Insurance Co. of Canada",
	},
	{
		label: "Primus Telecom Canada",
		value: "Primus Telecom Canada",
	},
	{
		label: "Priority Submetering Solutions",
		value: "Priority Submetering Solutions",
	},
	{
		label: "Progressa (formerly Creditloans)",
		value: "Progressa (formerly Creditloans)",
	},
	{
		label: "Prolink Insurance Inc (prev LMS Prolink)",
		value: "Prolink Insurance Inc (prev LMS Prolink)",
	},
	{
		label: "Provident Energy Management",
		value: "Provident Energy Management",
	},
	{
		label: "Province of New Brunswick - Property Tax",
		value: "Province of New Brunswick - Property Tax",
	},
	{
		label: "Pure Water Brand",
		value: "Pure Water Brand",
	},
	{
		label: "Qtrade Advisor",
		value: "Qtrade Advisor",
	},
	{
		label: "Qtrade Investor",
		value: "Qtrade Investor",
	},
	{
		label: "Quadient Canada Ltd",
		value: "Quadient Canada Ltd",
	},
	{
		label: "Queen's University",
		value: "Queen's University",
	},
	{
		label: "Questrade",
		value: "Questrade",
	},
	{
		label: "Raymond James Ltd.",
		value: "Raymond James Ltd.",
	},
	{
		label: "RBC Direct Investing Inc",
		value: "RBC Direct Investing Inc",
	},
	{
		label: "RBC Dominion Securities Inc",
		value: "RBC Dominion Securities Inc",
	},
	{
		label: "RBC Life Insurance Co - Group",
		value: "RBC Life Insurance Co - Group",
	},
	{
		label: "RBC Life Insurance Co - Individual",
		value: "RBC Life Insurance Co - Individual",
	},
	{
		label: "RE.Novate - TDFS",
		value: "RE.Novate - TDFS",
	},
	{
		label: "Real Estate Lawyers.ca LLP",
		value: "Real Estate Lawyers.ca LLP",
	},
	{
		label: "Refresh Financial Card Payments",
		value: "Refresh Financial Card Payments",
	},
	{
		label: "Reliance",
		value: "Reliance",
	},
	{
		label: "Reliance Comfort Limited Partnership",
		value: "Reliance Comfort Limited Partnership",
	},
	{
		label: "REM Insurance",
		value: "REM Insurance",
	},
	{
		label: "Rent Payment - Payment PAD",
		value: "Rent Payment - Payment PAD",
	},
	{
		label: "Resolve Financial Recovery Inc",
		value: "Resolve Financial Recovery Inc",
	},
	{
		label: "RIFCO National Auto Finance",
		value: "RIFCO National Auto Finance",
	},
	{
		label: "Rogers (12 Digit Account Number)",
		value: "Rogers (12 Digit Account Number)",
	},
	{
		label: "Rogers (6 Digit Account Number)",
		value: "Rogers (6 Digit Account Number)",
	},
	{
		label: "Rogers (9 Digit Account Number)",
		value: "Rogers (9 Digit Account Number)",
	},
	{
		label: "Rogers Paygo/Prepaid",
		value: "Rogers Paygo/Prepaid",
	},
	{
		label: "Rona Credit Cards",
		value: "Rona Credit Cards",
	},
	{
		label: "RONA Inc / Ontario Corporate Stores",
		value: "RONA Inc / Ontario Corporate Stores",
	},
	{
		label: "Royal & Sun Alliance Insurance",
		value: "Royal & Sun Alliance Insurance",
	},
	{
		label: "Royal Canin Canada",
		value: "Royal Canin Canada",
	},
	{
		label: "Royal Roads University",
		value: "Royal Roads University",
	},
	{
		label: "Russell A Farrow Limited",
		value: "Russell A Farrow Limited",
	},
	{
		label: "Ryerson University - Tuition & Residence",
		value: "Ryerson University - Tuition & Residence",
	},
	{
		label: "S&Y Insurance (Aviva Agency)",
		value: "S&Y Insurance (Aviva Agency)",
	},
	{
		label: "SaksFirst Credit Card",
		value: "SaksFirst Credit Card",
	},
	{
		label: "Sandpiper Energy Solutions",
		value: "Sandpiper Energy Solutions",
	},
	{
		label: "Sarnia Credit Recovery",
		value: "Sarnia Credit Recovery",
	},
	{
		label: "Sarnia Taxes, City of",
		value: "Sarnia Taxes, City of",
	},
	{
		label: "Sasktel Mobility",
		value: "Sasktel Mobility",
	},
	{
		label: "Sault College of Applied Arts & Tech",
		value: "Sault College of Applied Arts & Tech",
	},
	{
		label: "Scholastic Canada Ltd.",
		value: "Scholastic Canada Ltd.",
	},
	{
		label: "Scotia - Student Loans (Student Plan)",
		value: "Scotia - Student Loans (Student Plan)",
	},
	{
		label: "Scotia Bank Line of Credit",
		value: "Scotia Bank Line of Credit",
	},
	{
		label: "Scotia Dealer Advantage",
		value: "Scotia Dealer Advantage",
	},
	{
		label: "Scotia iTrade Corp (formerly E-Trade Canada)",
		value: "Scotia iTrade Corp (formerly E-Trade Canada)",
	},
	{
		label: "Scotia Plan Loan",
		value: "Scotia Plan Loan",
	},
	{
		label: "Scotiabank American Express",
		value: "Scotiabank American Express",
	},
	{
		label: "Scotiabank Govt Student Loan Admin Ctr",
		value: "Scotiabank Govt Student Loan Admin Ctr",
	},
	{
		label: "Scotiabank Retailer Card",
		value: "Scotiabank Retailer Card",
	},
	{
		label: "ScotiaMcLeod",
		value: "ScotiaMcLeod",
	},
	{
		label: "Scottish & York Insurance Co Ltd",
		value: "Scottish & York Insurance Co Ltd",
	},
	{
		label: "SCP Distributors Canada Inc",
		value: "SCP Distributors Canada Inc",
	},
	{
		label: "Sears Connect Long Distance",
		value: "Sears Connect Long Distance",
	},
	{
		label: "Security One Alarm Systems",
		value: "Security One Alarm Systems",
	},
	{
		label: "Seguin, Township - Property Tax",
		value: "Seguin, Township - Property Tax",
	},
	{
		label: "Selectcom Telecom",
		value: "Selectcom Telecom",
	},
	{
		label: "Seneca College of Applied Arts & Tech",
		value: "Seneca College of Applied Arts & Tech",
	},
	{
		label: "Services Financiers Groupe BMR",
		value: "Services Financiers Groupe BMR",
	},
	{
		label: "Severn, Township - Taxes",
		value: "Severn, Township - Taxes",
	},
	{
		label: "Shaw Cable",
		value: "Shaw Cable",
	},
	{
		label: "Shaw Direct (formerly Star Choice)",
		value: "Shaw Direct (formerly Star Choice)",
	},
	{
		label: "Shaw Hamilton",
		value: "Shaw Hamilton",
	},
	{
		label: "Shelburne, Town - Taxes",
		value: "Shelburne, Town - Taxes",
	},
	{
		label: "Shell Canada Products",
		value: "Shell Canada Products",
	},
	{
		label: "Shell Energy North America (Canada Inc)",
		value: "Shell Energy North America (Canada Inc)",
	},
	{
		label: "Sheridan College - Residence",
		value: "Sheridan College - Residence",
	},
	{
		label: "Sheridan College - Tuition",
		value: "Sheridan College - Tuition",
	},
	{
		label: "Shopping Cannel Credit Card",
		value: "Shopping Cannel Credit Card",
	},
	{
		label: "Silver Fox Pharmacy Inc",
		value: "Silver Fox Pharmacy Inc",
	},
	{
		label: "Silver Gold Bull Inc",
		value: "Silver Gold Bull Inc",
	},
	{
		label: "Simon Fraser University",
		value: "Simon Fraser University",
	},
	{
		label: "SiriusXM Canada Inc",
		value: "SiriusXM Canada Inc",
	},
	{
		label: "Skylink Receivables Inc",
		value: "Skylink Receivables Inc",
	},
	{
		label: "Slimband Incorporated",
		value: "Slimband Incorporated",
	},
	{
		label: "Smith Falls, Town - Taxes",
		value: "Smith Falls, Town - Taxes",
	},
	{
		label: "Smith Falls, Town - Water",
		value: "Smith Falls, Town - Water",
	},
	{
		label: "Snap Financial Corp",
		value: "Snap Financial Corp",
	},
	{
		label: "SOCAN",
		value: "SOCAN",
	},
	{
		label: "Solo Mobile",
		value: "Solo Mobile",
	},
	{
		label: "South Algonquin, Township - Taxes",
		value: "South Algonquin, Township - Taxes",
	},
	{
		label: "South Bruce Peninsula, Town - Taxes",
		value: "South Bruce Peninsula, Town - Taxes",
	},
	{
		label: "South Bruce Peninsula, Town - Utilities",
		value: "South Bruce Peninsula, Town - Utilities",
	},
	{
		label: "SouthWest Agromart Ltd",
		value: "SouthWest Agromart Ltd",
	},
	{
		label: "Southwest Middlesex, Mun - Utilities",
		value: "Southwest Middlesex, Mun - Utilities",
	},
	{
		label: "SPM GWL Savings Plan",
		value: "SPM GWL Savings Plan",
	},
	{
		label: "St Clair College Tuition",
		value: "St Clair College Tuition",
	},
	{
		label: "St Clair Taxes, Township of (Mooretown)",
		value: "St Clair Taxes, Township of (Mooretown)",
	},
	{
		label: "St. Catharines Water (ON)",
		value: "St. Catharines Water (ON)",
	},
	{
		label: "St. Catharines, City - Taxes (ON)",
		value: "St. Catharines, City - Taxes (ON)",
	},
	{
		label: "St. Francis Xavier University",
		value: "St. Francis Xavier University",
	},
	{
		label: "St. Joseph, Township - Taxes",
		value: "St. Joseph, Township - Taxes",
	},
	{
		label: "St. Joseph, Township - Water & Sewage",
		value: "St. Joseph, Township - Water & Sewage",
	},
	{
		label: "St. Lawrence College - Tuition Fees",
		value: "St. Lawrence College - Tuition Fees",
	},
	{
		label: "St. Marys, Town - Taxes",
		value: "St. Marys, Town - Taxes",
	},
	{
		label: "St. Thomas University",
		value: "St. Thomas University",
	},
	{
		label: "Stack Fintech Inc",
		value: "Stack Fintech Inc",
	},
	{
		label: "Staples - Citi Commerce",
		value: "Staples - Citi Commerce",
	},
	{
		label: "Staples - Desjardins Credit Card",
		value: "Staples - Desjardins Credit Card",
	},
	{
		label: "Start.ca",
		value: "Start.ca",
	},
	{
		label: "Startec Canada",
		value: "Startec Canada",
	},
	{
		label: "Sterling Mutuals Inc.",
		value: "Sterling Mutuals Inc.",
	},
	{
		label: "Strathroy-Caradoc, Township - Taxes",
		value: "Strathroy-Caradoc, Township - Taxes",
	},
	{
		label: "Strong, Township - Property Tax",
		value: "Strong, Township - Property Tax",
	},
	{
		label: "Suite Excel Collections Canada Inc",
		value: "Suite Excel Collections Canada Inc",
	},
	{
		label: "Summit Food Service Distributors Inc",
		value: "Summit Food Service Distributors Inc",
	},
	{
		label: "Sun Life Personal Insurance",
		value: "Sun Life Personal Insurance",
	},
	{
		label: "Superior Propane Inc",
		value: "Superior Propane Inc",
	},
	{
		label: "Synergy North",
		value: "Synergy North",
	},
	{
		label: "Synnex Canada Ltd",
		value: "Synnex Canada Ltd",
	},
	{
		label: "Sysco - Kingston",
		value: "Sysco - Kingston",
	},
	{
		label: "Sysco - Milton",
		value: "Sysco - Milton",
	},
	{
		label: "Tasco Appliances",
		value: "Tasco Appliances",
	},
	{
		label: "Tay, Township - Property Tax",
		value: "Tay, Township - Property Tax",
	},
	{
		label: "TBAYTEL",
		value: "TBAYTEL",
	},
	{
		label: "TD Auto Finance (Canada)",
		value: "TD Auto Finance (Canada)",
	},
	{
		label: "TD Insurance Home & Auto",
		value: "TD Insurance Home & Auto",
	},
	{
		label: "TD Line of Credit/Student Line of Credit",
		value: "TD Line of Credit/Student Line of Credit",
	},
	{
		label: "TD Waterhouse",
		value: "TD Waterhouse",
	},
	{
		label: "Tecumseh Taxes, Town of",
		value: "Tecumseh Taxes, Town of",
	},
	{
		label: "TekSavvy Solutions",
		value: "TekSavvy Solutions",
	},
	{
		label: "TELEHOP",
		value: "TELEHOP",
	},
	{
		label: "Telepay Payables Trust",
		value: "Telepay Payables Trust",
	},
	{
		label: "Telepay Payroll Trust",
		value: "Telepay Payroll Trust",
	},
	{
		label: "Telus Communications Inc. (B.C. Incl)",
		value: "Telus Communications Inc. (B.C. Incl)",
	},
	{
		label: "Telus Mobility",
		value: "Telus Mobility",
	},
	{
		label: "TenantPay",
		value: "TenantPay",
	},
	{
		label: "Tepperman's",
		value: "Tepperman's",
	},
	{
		label: "Thames Valley Children's Centre",
		value: "Thames Valley Children's Centre",
	},
	{
		label: "The Andersons Canada Limited",
		value: "The Andersons Canada Limited",
	},
	{
		label: "The Guarantee Company - Brokers",
		value: "The Guarantee Company - Brokers",
	},
	{
		label: "The Master Group",
		value: "The Master Group",
	},
	{
		label: "The Nation Municipality - Taxes",
		value: "The Nation Municipality - Taxes",
	},
	{
		label: "The Nation Municipality - Utilities",
		value: "The Nation Municipality - Utilities",
	},
	{
		label: "The National Benefit Authority Corp",
		value: "The National Benefit Authority Corp",
	},
	{
		label: "The Personal Insurance Company",
		value: "The Personal Insurance Company",
	},
	{
		label: "The Portage la Prairie Mutual Insur Co",
		value: "The Portage la Prairie Mutual Insur Co",
	},
	{
		label: "The Reader's Digest Assoc Canada",
		value: "The Reader's Digest Assoc Canada",
	},
	{
		label: "The Sources - Desjardins",
		value: "The Sources - Desjardins",
	},
	{
		label: "The Toronto Wholesale Produce Assoc",
		value: "The Toronto Wholesale Produce Assoc",
	},
	{
		label: "The United Church of Canada",
		value: "The United Church of Canada",
	},
	{
		label: "Thorold, City - Taxes",
		value: "Thorold, City - Taxes",
	},
	{
		label: "Thrasher Sales & Leasing Ltd",
		value: "Thrasher Sales & Leasing Ltd",
	},
	{
		label: "Thunder Bay Water",
		value: "Thunder Bay Water",
	},
	{
		label: "Thunder Bay, City - Property Tax",
		value: "Thunder Bay, City - Property Tax",
	},
	{
		label: "Thunder Bay, City - Provincial Tax",
		value: "Thunder Bay, City - Provincial Tax",
	},
	{
		label: "Timbercreek Asset Management",
		value: "Timbercreek Asset Management",
	},
	{
		label: "Tiny, Town - Taxes",
		value: "Tiny, Town - Taxes",
	},
	{
		label: "Torbram Electric Supply Western",
		value: "Torbram Electric Supply Western",
	},
	{
		label: "Toronto Hydro-Electric System Ltd.",
		value: "Toronto Hydro-Electric System Ltd.",
	},
	{
		label: "Toronto, City - Taxes",
		value: "Toronto, City - Taxes",
	},
	{
		label: "Toronto, City - Utility Bill",
		value: "Toronto, City - Utility Bill",
	},
	{
		label: "Total Credit Recovery",
		value: "Total Credit Recovery",
	},
	{
		label: "Toyota Credit Canada Inc",
		value: "Toyota Credit Canada Inc",
	},
	{
		label: "Traders General Insurance Company",
		value: "Traders General Insurance Company",
	},
	{
		label: "Trafalgar Insurance Company (13 digit)",
		value: "Trafalgar Insurance Company (13 digit)",
	},
	{
		label: "TransferWise",
		value: "TransferWise",
	},
	{
		label: "Trent University",
		value: "Trent University",
	},
	{
		label: "Trinity Western University",
		value: "Trinity Western University",
	},
	{
		label: "Tuckersmith Communications Co-Op Ltd",
		value: "Tuckersmith Communications Co-Op Ltd",
	},
	{
		label: "Uline Canada Corporation",
		value: "Uline Canada Corporation",
	},
	{
		label: "Unica Insurance Inc",
		value: "Unica Insurance Inc",
	},
	{
		label: "Unifund Assurance Company",
		value: "Unifund Assurance Company",
	},
	{
		label: "University of Guelph - Humber",
		value: "University of Guelph - Humber",
	},
	{
		label: "University of Guelph - Residence Deposits",
		value: "University of Guelph - Residence Deposits",
	},
	{
		label: "University of Guelph - Student Fees",
		value: "University of Guelph - Student Fees",
	},
	{
		label: "University of Manitoba",
		value: "University of Manitoba",
	},
	{
		label: "University of On. Inst. Of Technology",
		value: "University of On. Inst. Of Technology",
	},
	{
		label: "University of Ottawa - Tuition & Other",
		value: "University of Ottawa - Tuition & Other",
	},
	{
		label: "University of Toronto",
		value: "University of Toronto",
	},
	{
		label: "University of Waterloo",
		value: "University of Waterloo",
	},
	{
		label: "University of Windsor",
		value: "University of Windsor",
	},
	{
		label: "UPI Energy LP",
		value: "UPI Energy LP",
	},
	{
		label: "Urban Effects",
		value: "Urban Effects",
	},
	{
		label: "US Bank National Association",
		value: "US Bank National Association",
	},
	{
		label: "Utilities Kingston",
		value: "Utilities Kingston",
	},
	{
		label: "Uxbridge, Township - Taxes",
		value: "Uxbridge, Township - Taxes",
	},
	{
		label: "Valor Distributions",
		value: "Valor Distributions",
	},
	{
		label: "Vancity VISA (includes Save-on Visa)",
		value: "Vancity VISA (includes Save-on Visa)",
	},
	{
		label: "Veridian Connections Inc",
		value: "Veridian Connections Inc",
	},
	{
		label: "Veritas Alliance Incorporated",
		value: "Veritas Alliance Incorporated",
	},
	{
		label: "Vetta Building Technologies Inc",
		value: "Vetta Building Technologies Inc",
	},
	{
		label: "VFC Inc.",
		value: "VFC Inc.",
	},
	{
		label: "Virgin Mobile (Client ID 14 Digits)",
		value: "Virgin Mobile (Client ID 14 Digits)",
	},
	{
		label: "Visa - Bank of America Canada - Corporate",
		value: "Visa - Bank of America Canada - Corporate",
	},
	{
		label: "Visa - Bank One",
		value: "Visa - Bank One",
	},
	{
		label: "Visa - Brick Desjardins Card",
		value: "Visa - Brick Desjardins Card",
	},
	{
		label: "Visa - Canada Post Prepaid Card",
		value: "Visa - Canada Post Prepaid Card",
	},
	{
		label: "Visa - CIBC",
		value: "Visa - CIBC",
	},
	{
		label: "Visa - Citibank",
		value: "Visa - Citibank",
	},
	{
		label: "Visa - Collabria Canadian Dollar",
		value: "Visa - Collabria Canadian Dollar",
	},
	{
		label: "Visa - Community",
		value: "Visa - Community",
	},
	{
		label: "Visa - Desjardins",
		value: "Visa - Desjardins",
	},
	{
		label: "Visa - First Line",
		value: "Visa - First Line",
	},
	{
		label: "Visa - Home Trust",
		value: "Visa - Home Trust",
	},
	{
		label: "Visa - Leons Visa Desjardin",
		value: "Visa - Leons Visa Desjardin",
	},
	{
		label: "Visa - Manulife Bank",
		value: "Visa - Manulife Bank",
	},
	{
		label: "Visa - MBNA",
		value: "Visa - MBNA",
	},
	{
		label: "Visa - Meridian",
		value: "Visa - Meridian",
	},
	{
		label: "Visa - Nextwave Titanium Plus Prepaid Card",
		value: "Visa - Nextwave Titanium Plus Prepaid Card",
	},
	{
		label: "Visa - Royal Bank",
		value: "Visa - Royal Bank",
	},
	{
		label: "Visa - Scotia",
		value: "Visa - Scotia",
	},
	{
		label: "Visa - Simplii Financial Visa Card",
		value: "Visa - Simplii Financial Visa Card",
	},
	{
		label: "Visa - TD",
		value: "Visa - TD",
	},
	{
		label: "Visa - Vancity Com. Inv. Bank Prepaid",
		value: "Visa - Vancity Com. Inv. Bank Prepaid",
	},
	{
		label: "Visa - Vancity Prepaid",
		value: "Visa - Vancity Prepaid",
	},
	{
		label: "Vista Innovative Promotions",
		value: "Vista Innovative Promotions",
	},
	{
		label: "VMedia",
		value: "VMedia",
	},
	{
		label: "Voice Network Inc",
		value: "Voice Network Inc",
	},
	{
		label: "VW Credit Canada Inc.",
		value: "VW Credit Canada Inc.",
	},
	{
		label: "Waddick Fuels O/A Kent Petroleum Limited",
		value: "Waddick Fuels O/A Kent Petroleum Limited",
	},
	{
		label: "Waste Connections of Canada Inc",
		value: "Waste Connections of Canada Inc",
	},
	{
		label: "Waterloo Insurance Company",
		value: "Waterloo Insurance Company",
	},
	{
		label: "Waterloo North Hydro",
		value: "Waterloo North Hydro",
	},
	{
		label: "Waterloo Taxes",
		value: "Waterloo Taxes",
	},
	{
		label: "Waterloo, City - Water",
		value: "Waterloo, City - Water",
	},
	{
		label: "Wave Direct",
		value: "Wave Direct",
	},
	{
		label: "Wawanesa Insurance Company",
		value: "Wawanesa Insurance Company",
	},
	{
		label: "Weed Man (Hamilton)",
		value: "Weed Man (Hamilton)",
	},
	{
		label: "Wellesley, Township - Taxes",
		value: "Wellesley, Township - Taxes",
	},
	{
		label: "Wells Fargo Business Credit",
		value: "Wells Fargo Business Credit",
	},
	{
		label: "West Elgin Mutual Insurance Co",
		value: "West Elgin Mutual Insurance Co",
	},
	{
		label: "West Grey, Municipality - Taxes",
		value: "West Grey, Municipality - Taxes",
	},
	{
		label: "West Nipissing, Municipality of (Taxes)",
		value: "West Nipissing, Municipality of (Taxes)",
	},
	{
		label: "West Nipissing, Water & Sewer",
		value: "West Nipissing, Water & Sewer",
	},
	{
		label: "Western Assurance Co.",
		value: "Western Assurance Co.",
	},
	{
		label: "Western Univ - Apartments",
		value: "Western Univ - Apartments",
	},
	{
		label: "Western University - Tuition and Residence",
		value: "Western University - Tuition and Residence",
	},
	{
		label: "Wex Canada Ltd",
		value: "Wex Canada Ltd",
	},
	{
		label: "Whitby Taxes",
		value: "Whitby Taxes",
	},
	{
		label: "Whitestone, Municipality - Property Tax",
		value: "Whitestone, Municipality - Property Tax",
	},
	{
		label: "Wilfrid Laurier University",
		value: "Wilfrid Laurier University",
	},
	{
		label: "Windsor Club",
		value: "Windsor Club",
	},
	{
		label: "Windsor Essex Community Housing",
		value: "Windsor Essex Community Housing",
	},
	{
		label: "Windsor Police Services",
		value: "Windsor Police Services",
	},
	{
		label: "Windsor Star - Subscriptions",
		value: "Windsor Star - Subscriptions",
	},
	{
		label: "Windsor Taxes, City of",
		value: "Windsor Taxes, City of",
	},
	{
		label: "Windsor Yacht Club",
		value: "Windsor Yacht Club",
	},
	{
		label: "WinTel Communications",
		value: "WinTel Communications",
	},
	{
		label: "Wise Line Metal Sales Inc",
		value: "Wise Line Metal Sales Inc",
	},
	{
		label: "Wolseley Canada Inc",
		value: "Wolseley Canada Inc",
	},
	{
		label: "Woodstock, City - Property Taxes",
		value: "Woodstock, City - Property Taxes",
	},
	{
		label: "Woolwich, Township - Taxes",
		value: "Woolwich, Township - Taxes",
	},
	{
		label: "Workplace Safety & Insurance Board Schedule 1",
		value: "Workplace Safety & Insurance Board Schedule 1",
	},
	{
		label: "Workplace Safety & Insurance Board Schedule 2",
		value: "Workplace Safety & Insurance Board Schedule 2",
	},
	{
		label: "Worldline",
		value: "Worldline",
	},
	{
		label: "World-Link Communications Inc",
		value: "World-Link Communications Inc",
	},
	{
		label: "Wurth Canada Limited",
		value: "Wurth Canada Limited",
	},
	{
		label: "Wyse Meter Solutions",
		value: "Wyse Meter Solutions",
	},
	{
		label: "Xplornet Communications Inc",
		value: "Xplornet Communications Inc",
	},
	{
		label: "YAK Communications",
		value: "YAK Communications",
	},
	{
		label: "Yamaha Financial Services",
		value: "Yamaha Financial Services",
	},
	{
		label: "Yard Card Canada - TDFS",
		value: "Yard Card Canada - TDFS",
	},
	{
		label: "Yellow Pages",
		value: "Yellow Pages",
	},
	{
		label: "Yokohama Tire (Canada) inc",
		value: "Yokohama Tire (Canada) inc",
	},
	{
		label: "York Property Management",
		value: "York Property Management",
	},
	{
		label: "York University",
		value: "York University",
	},
	{
		label: "Zenith Insurance Company",
		value: "Zenith Insurance Company"
	}];
