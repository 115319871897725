import React, {PropsWithChildren, useEffect, useState} from "react";
import {cloneDeep} from "lodash";
import {CgClose, FaChevronDown} from "react-icons/all";
import OutsideOnClick from "../../utils/hooks/useOnClickOutside";
import {IAutoCompleteOption} from "./CryptoAutoComplete";

interface IProps extends PropsWithChildren<any> {
	name?: string;
	options?: IAutoCompleteOption[];
	value: any;
	setValue: any;
	placeholder?: string;
	className?: string;
	disabled?: boolean;
	searchable?: boolean;	//
	multiSelect?: boolean;	// TODO this current iteration of multiselect is not searchable at the same time, refactor later
	icon?: JSX.Element;
}

const SelectDropdown: React.FC<IProps> = (props): JSX.Element => {

	const {name, options, value, setValue, className, placeholder, multiSelect, searchable, disabled, icon} = props;
	const [open, setOpen] = useState(false);

	// temporary rendering of filtered dropdown items
	const [tempArray, setTempArray] = useState([]);
	// display label for input, not actual value of what is entered. the real value is set using setValue
	const [displayLabel, setDisplayLabel] = useState<string>();

	useEffect(() => {
		if (multiSelect) {
			setTempArray(options.filter(item => item.label.includes(value[0])));
			if (typeof value[0] === "undefined") {
				setDisplayLabel("");
			}
		} else {
			setTempArray(options.filter(item => item.label.includes(value)));
			if (value === "") {
				setDisplayLabel("");
			}
		}
	}, [value, options]);

	function handleOnClick(item: IAutoCompleteOption) {
		if (!multiSelect) {
			setValue(item.label);
			setDisplayLabel(item.label);
			toggle();
		} else {
			if (!value.some(current => current.label === item.label)) {
				setValue([...cloneDeep(value), item])
			} else {
				let selectionAferRemoval = cloneDeep(value);
				selectionAferRemoval = selectionAferRemoval.filter(current => current.label !== item.label)
				setValue([...selectionAferRemoval]);
			}
		}
	}

	function filterSelection(e: any) {
		if (multiSelect) {
			setValue([e.target.value]);
		} else {
			setValue(e.target.value);
		}
		setDisplayLabel(e.target.value);
	}

	function isItemInSelection(item) {
		if (Array.isArray(value)) {
			return !!value.find(element => element.label === item.label);
		}
		return;
	}

	function deleteOption(selection: IAutoCompleteOption) {
		setValue(value.filter(item => item !== selection));
	}

	function createMultiOption(option: IAutoCompleteOption, index: number): JSX.Element {
		return (
			<div key={`dropdown-multi-dropdown__item-${index}`} className="custom-multi-dropdown__container__item"
				 onClick={() => deleteOption(option)}
			>
				{option.label}
				<span className="pl-3">
                    <CgClose/>
                </span>
			</div>
		);
	}

	const openDropdown = () => {
		if (!disabled && searchable) {
			setTempArray(options);
			setOpen(true);
		}
	}

	const toggle = () => {
		if (!disabled) {
			setOpen(!open);
		}
	}

	return (
		<OutsideOnClick onClickOutside={() => setOpen(false)}>
			<div className={`dd-wrapper ${className} ${disabled ? "opacity-50" : ""}`}>
				{!searchable ? (
					<div
						tabIndex={0}
						className="dd-header w-100"
						role="button"
						onKeyPress={!searchable ? toggle : openDropdown}
						onClick={!searchable ? toggle : openDropdown}
						title={name}
					>
						<div className="dd-header__title">
							<span
								className="dd-header__title--bold"
								style={{opacity: value.length < 1 ? 0.5 : 1}}
							>
								{!multiSelect ? (value.length !== 0 ? value[value.length - 1]?.label : placeholder) : placeholder}
							</span>
						</div>
						<div className="dd-header__action">
							{/*<img src={FaChevronUp} alt="chevron" className={open ? "rotate" : "rotate-180"} style={{transition: "transform 150ms ease"}}/>*/}
							{icon ? icon : <FaChevronDown/>}
						</div>
					</div>
				) : (
					<div className="dd-input">
						<input onClick={openDropdown} value={displayLabel} onChange={filterSelection}
							   placeholder={placeholder}/>
						<div className="dd-input__icon">
							{icon ? icon : <FaChevronDown/>}
						</div>
					</div>
				)}
				{open && (
					<div className="dd-list-container"
						 style={{overflowX: options ? (options?.length > 4 ? "scroll" : "hidden") : "hidden"}}>
						<ul className="dd-list">
							{(!searchable) ?
								(options.map((item) => {
										return (
											<li className="dd-list-item" key={item.label}>
												<button
													type="button"
													onClick={() => handleOnClick(item)}
												>
													<span
														className={isItemInSelection(item) ? "font-weight-bold" : "font-weight-normal"}>{item.label}</span>
													<span>{isItemInSelection(item) ? "Selected" : ""}</span>
												</button>
											</li>
										)
									})
								) : (
									tempArray.map(item => (
										<li className="dd-list-item" key={item.label}>
											<button
												type="button"
												onClick={() => handleOnClick(item)}
											>
												<span className={isItemInSelection(item) ?
													"font-weight-bold"
													:
													"font-weight-normal"
												}>{item.label}</span>
												<span>{isItemInSelection(item) ? "Selected" : ""}</span>
											</button>
										</li>
									))
								)
							}
						</ul>
					</div>
				)}
				{(multiSelect === true) && (
					<>
						{value?.map((item: IAutoCompleteOption, index: number) => createMultiOption(item, index))}
					</>
				)}
			</div>
		</OutsideOnClick>
	);
}

SelectDropdown.defaultProps = {
	multiSelect: false,
	options: [],
	className: "",
	placeholder: "Select an option.",
	searchable: false,
	disabled: false,
}

export default SelectDropdown;
