import * as React from "react";
import {useState} from "react";
import {Col, Container, Row} from "reactstrap";
import BillingForm from "./BillingForm";
import BillingCreditForm from "./BillingCreditForm";
import {HiLightBulb} from "react-icons/all";
import BillProcessingModal from "../user/BillProcessingModal";
import ThirdPartyModal from "../user/ThirdPartyModal";
import {CreateTransactionBody, TransactionsApi, TransactionType} from "client";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import { connect } from "react-redux";
import getConfig from "../../../utils/getConfig";
import {IStore} from "../../../redux/defaultStore";

interface IProps {
	callback?(): void;
	dispatch?: any;
	token?: string;
	toggleSignUpModal?: () => void;
}

const howDoesThisWorkParagraph =
	"If you want to pay either a bill or credit card payment using crypto we will do it on your behalf.";

const ModalCard: React.FC<IProps> = (props): JSX.Element => {

	const [activeTab, setActiveTab] = useState(TransactionType.BILL);
	const [thirdPartyModal, setThirdPartyModal] = useState(false);
	const [processingModal, setProcessingModal] = useState(false);

	const [billingData, setBillingData] = useState({});
	const [confirmationData, setConfirmationData] = useState({});

	function toggleSignUpModal() {
		props.toggleSignUpModal();
	}

	function callThirdPartyAPI(tabType: TransactionType): (data: any) => void {
		if (!props.token) {
			return toggleSignUpModal;
		}

		return (async (data: CreateTransactionBody) => {
			props.dispatch(incrementLoading());
			setBillingData(data);
			try {
				const res = await new TransactionsApi(getConfig(props.token)).createTransaction({
					createTransactionBody: data
				});
				window.location.assign(res.url);
			} catch (err) {
				props.dispatch(addError(err));
			}
			props.dispatch(decrementLoading());
		})
	}

	function thirdPartyCompleteCallback(data: any) {
		setConfirmationData(data);
		toggleThirdPartyModal();
		toggleProcessingModal()
	}

	function toggleThirdPartyModal() {
		setThirdPartyModal(!thirdPartyModal);
	}

	function toggleProcessingModal() {
		setProcessingModal(!processingModal);
	}

	function setTabToBills() {
		setActiveTab(TransactionType.BILL);
	}

	function setTabToCreditCards() {
		setActiveTab(TransactionType.CREDITCARD);
	}

	return (
		<>
			<div className="modal-card">

				<div className="modal-card__container">
					<span
						className={`modal-card__tab ${activeTab === TransactionType.BILL && "modal-card__tab--active"}`}
						onClick={setTabToBills}
						style={{cursor: "pointer"}}
					>
						Pay Your Bills
					</span>
					<span
						className={`modal-card__tab ${activeTab === TransactionType.CREDITCARD && "modal-card__tab--active"}`}
						onClick={setTabToCreditCards}
						style={{cursor: "pointer"}}
					>
						Pay Credit Cards
					</span>
				</div>

				<div className="d-flex w-100 justify-content-center align-items-center">
					<Container>
						<Row>
							<Col xs={12}>
								{activeTab === TransactionType.BILL && (
									<BillingForm
										onSubmit={callThirdPartyAPI(TransactionType.BILL)}
									/>
								)}
							</Col>
							<Col xs={12}>
								{activeTab === TransactionType.CREDITCARD && (
									<BillingCreditForm
										onSubmit={callThirdPartyAPI(TransactionType.CREDITCARD)}
									/>
								)}
							</Col>
						</Row>
					</Container>
				</div>

				<section className="d-none d-md-block">

					<div className="hr pb-3 mb-3"/>

					<div className="d-flex">
						<HiLightBulb size="1.5rem" color="cyan"/>
						<h4 className="px-2">How Does This Work?</h4>
					</div>

					<div className="d-none d-md-flex">
						<p>
							<strong>
								{howDoesThisWorkParagraph}
							</strong>
						</p>
					</div>
					<ul>
						<li className="mb-2">
							You tell us how much your bill is in Canadian fiat.
						</li>
						<li className="mb-2">
							You make the payment in either BTC or DOGE
						</li>
						<li className="mb-2">
							One of our staff will then make a submit the bill payment on your behalf. We will update you once it's done!
						</li>
					</ul>
					<span><strong>...it's that simple!</strong></span>
				</section>

			</div>

			<section className="d-flex flex-column d-md-none pt-3">
				<div className="d-flex">
					<HiLightBulb size="1.5rem" color="cyan"/>
					<h4 className="px-2">How Does This Work?</h4>
				</div>

				<div className="d-flex">
					<p>
						<strong>
							{howDoesThisWorkParagraph}
						</strong>
					</p>
				</div>
			</section>

			<ThirdPartyModal
				isOpen={thirdPartyModal}
				onClose={toggleThirdPartyModal}
				data={billingData}
				callback={thirdPartyCompleteCallback}
			/>

			<BillProcessingModal
				isOpen={processingModal}
				onClose={toggleProcessingModal}
				data={confirmationData}
			/>

		</>

	);
}

ModalCard.defaultProps = {}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
	}
})(ModalCard);
