import * as React from "react";
import {DetailedHTMLProps, useEffect} from "react";
import {InputType} from "reactstrap/lib/Input";
import {getBrowser} from "../../utils/variables";

export interface IInputNumberProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	name: string;
	label?: string;
	value: number;
	setValue: (value: any | ((prevVar: any) => any)) => void;
	placeholder?: string;
	type?: InputType;
	maxLength?: number;
	customType?: EInputNumberType;
	inputClasses?: string;
}

export enum EInputNumberType {
	DATE = "text",
	TEL = "tel",
}

const InputNumber: React.FC<IInputNumberProps> = (props): JSX.Element => {

	const {name, value, setValue, placeholder, type, customType, maxLength, inputClasses} = props;

	useEffect(() => {
		setValue("");
	}, []);
	function strictNumberInput(v: any): void {
		v.preventDefault();
		if (!isNaN(v.target.value[v.target.value.length - 1]) || (v.target.value[v.target.value.length - 1] === ".")) {
			setValue(v.target.value);
		} else {
			setValue(value);
		}
		if (v.target.value.length === 0) {
			setValue("");
		} else {
			if (typeof value === "undefined") {
				setValue("");
			}
		}

		const symbolRegex = RegExp(/[$-/:-?{-~!"^_`[\]]/);
		if (symbolRegex.test(v.target.value[v.target.value.length - 1]) && ((value.toString()).length > v.target.value.length)) {
			setValue(v.target.value.slice(0, -1));
		}
	}


	const formatInputValue = (v: any) => {

		async function _phoneType(v) {
			if (value !== undefined) {
				if ((value as any).toString().length === 4 && v.keyCode !== 8) {
					setValue(value => value.substring(0, 3) + " " + value.substring(3, value.toString().length));
				}
				if ((value as any).toString().length === 8 && v.keyCode !== 8) {
					setValue(value => value.substring(0, value.length - 1) + " " + value.substring(value.toString().length - 1, value.toString().length));
				} else {
				}
			}
		}

		async function _dateType(v) {
			if (value !== undefined) {
				if ((value as any).toString().length === 2 && v.keyCode !== 8) {
					setValue(value => value.substring(0, 3) + "/" + value.substring(3, value.toString().length));
				}
				if ((value as any).toString().length === 6 && v.keyCode !== 8) {
					setValue(value => value.substring(0, value.length - 1) + "/" + value.substring(value.toString().length - 1, value.toString().length));
				}
			}
		}

		if (customType) {
			switch (customType) {
				case EInputNumberType.TEL:
					_phoneType(v).then();
					break;
				case EInputNumberType.DATE:
					_dateType(v).then();
					break;
				default:
					break;
			}
		}
	}

	function customLength() {
		if (customType && !maxLength) {
			switch (customType) {
				case EInputNumberType.TEL:
					return 12;
				case EInputNumberType.DATE:
					return 10;
				default:
					return undefined;
			}
		} else {
			return undefined;
		}
	}

	const tempType: any = (getBrowser() && (getBrowser()?.name)?.toLowerCase() === "safari") ?
		((type === "date") ? EInputNumberType.DATE : type)
		:
		type
	;

	return (
		<input type={tempType} autoComplete="off" name={name} value={value} onChange={strictNumberInput}
			   onKeyDown={formatInputValue} maxLength={maxLength ? maxLength : customLength()}
			   placeholder={placeholder}
			   className={inputClasses}
		/>
	);
}

InputNumber.defaultProps = {
	type: "text",
	placeholder: "Enter a Number",
}

export default InputNumber;
